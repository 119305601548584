import { palette } from "lib/theme";
import React, { FC, useEffect, useState } from "react";
import { getText } from "shared/locale-helper";
import styled from "styled-components";
import { Heading, Text } from "components/atoms/Typography";
import Button from "components/atoms/Button";
import { TypesNamesEnum, SizeNamesEnum } from "enums/Button.enum";

const LtbInformationBody = ({ onClose }: { onClose: Function }) => {
  const listItems = [
    {
      key: 1,
      color: palette.green.main,
      class: "list-label",
      size: "small",
      labelText: "ltv_green",
      labelTextDesc: "ltv_green_desc",
    },
    {
      key: 2,
      color: palette.orange.main,
      class: "list-label",
      size: "small",
      labelText: "ltv_orange",
      labelTextDesc: "ltv_orange_desc",
    },
    {
      key: 3,
      color: palette.red.main,
      class: "list-label",
      size: "small",
      labelText: "ltv_red",
      labelTextDesc: "ltv_red_desc",
    },
    {
      key: 4,
      color: palette.white.light70,
      class: "list-label",
      size: "small",
      labelText: "ltv_gray",
      labelTextDesc: "ltv_gray_desc",
    },
  ];

  const getList = () => {
    return listItems.map((li) => {
      return (
        <ListItem color={`${li.color}`} key={li.key}>
          <Text size={li.size} color={li.color} className={li.class}>
            {`${getText(`${li.labelText}`)}:`}
          </Text>{" "}
          <Text size={li.size} color={palette.gray.main} className={li.class}>
            {getText(`${li.labelTextDesc}`)}
          </Text>
        </ListItem>
      );
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Container>
        <div>
          {" "}
          <Text color={palette.gray.main} size="small">
            {getText("ltv_refer")}
          </Text>
        </div>
        <div>
          {" "}
          <Text color={palette.gray.main} size="small">
            {getText("ltv_example")}
          </Text>
        </div>
        <div>
          <Text color={palette.gray.main} size="small">
            {getText("ltv_high")}
          </Text>
        </div>
        <div>
          <Heading color={`${palette.gray.main}`} variant="h6">
            {getText("color_definition")}
          </Heading>
          {getList()}
        </div>
      </Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          buttonType={TypesNamesEnum.SECONDAY}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label={getText("got_it")}
          onClick={() => onClose(false)}
        ></Button>
      </div>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray.main};
  flex-grow: 1;

  div {
    margin-bottom: 12px;
    padding: 0px 1px;
    color: ${palette.gray.main};
  }
`;

const ListItem = styled.li<{ color: string }>`
  color: ${({ color }) => color};
  color: ${({ color }) => color};
  margin-top: 3.5px;

  .list-label {
    position: relative;
    left: -12px;
  }
`;

export default LtbInformationBody;
