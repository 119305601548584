import React, { FC, useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";

import styled, { keyframes } from "styled-components";
import { iconsWithEffectJson } from "assets/iconsWithEffect";

interface IconWihEffectProps {
  iconName: string;
  onHover?: boolean;
}

const IconWithEffect: FC<IconWihEffectProps> = ({iconName, onHover}) => {
  const loadingRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!loadingRef.current) return;
      lottie.loadAnimation({
        container: loadingRef.current,
        animationData: iconsWithEffectJson[iconName as keyof typeof iconsWithEffectJson],
        name: iconName,
        loop: false,
        autoplay: false,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      });
  }, []);

  useEffect(() => {
    if(onHover){
      lottie.play(iconName);
    }
    else {
      lottie.setDirection(-1);
      lottie.stop(iconName);
    }
    }, [onHover])

  return (
    <ContainerAnimatedIcon>
      <div
        style={
          {
            width: "24px",
            height: "32px",
            marginBottom: "28%"
            
          }
        }
        ref={loadingRef}
      />
    </ContainerAnimatedIcon>
  );
};

export default IconWithEffect;

const ContainerAnimatedIcon = styled.div`
  display: flex;
  flex-direction: row;
`;
