import React from 'react';
import styled from 'styled-components';
import {CustodyStyledPrintableTable} from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import {useParams} from 'react-router-dom';
import {ExpenseSummary} from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import getUsDollar from 'shared/dollarFormat';

interface RecordData {
  [key: string]: ExpenseSummary[];
}
const CustodyStatementInvestmentExpenseSummary = () => {
  const {month, year} = useParams();
  const {expenseSummary} = useCustodyStatement({month, year});
  return (
    <div>
      <TokenSummaryTitle>
        Investment Expense Summary YTD<small>**</small>
      </TokenSummaryTitle>
      <CustodyStyledPrintableTable>
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
              <th>Cost Basis</th>
            </tr>
          </thead>
          <tbody>

          {expenseSummary.data && expenseSummary.data.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
      </CustodyStyledPrintableTable>
    </div>
  );
};

interface CustodySummaryRowProps {
  record: ExpenseSummary
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  const {getPriceFormattedI} = useAssets()
  return (
    <tr>
      <td>
        <MainRowText>
        {record.category}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getPriceFormattedI(record.amount.asset, record.amount?.amount ?? '0')}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getUsDollar(record.costBasisAmount?.amount)}
        </MainRowText>
      </td>
    </tr>
  )
}
const PairTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  @media print {
    color: black !important;
  }
`;

const MainRowText = styled.span`
`
const PairValue = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.48px;
  margin-bottom: 20px;
  @media print {
    color: black !important;
  }
`;
const BoxValues = styled.div``;

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default CustodyStatementInvestmentExpenseSummary;
