import {
  BankType,
  DefaultSettings,
  IconsAllOptions,
  RewardsProfileIcon,
} from "lib/constants/config.constant";
import getUserLocale from "./getUserLocale";
import { Buffer } from "buffer";
import { Assets } from "services/models";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { getText } from "./locale-helper";

export const uuid = () => {
  let dt = new Date().getTime();

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const base64toBlob = (b64: string, type: string = "image/png") => {
  const byteCharacters = Buffer.from(b64, "base64").toString("ascii");
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: type });

  return blob;
};

export const symbolsValidation = (symbols: string, text: string) => {
  const regex = RegExp(`[${symbols}]`);

  if (!text || regex.test(text) === false) {
    return false;
  }
  return true;
};

export const fixedTo = (number: number, decimalsPlace: number = 2) => {
  const value = Math.pow(10, decimalsPlace);
  return Math.round(number * value) / value;
};

export const emailValidation = (email: string) => {
  const regex = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
};

export const decimalCount = (number: any) => {
  const numberAsString = String(number);

  if (numberAsString.includes(".")) {
    let decimalLength = numberAsString.split(".")[1].length;
    return decimalLength;
  } else {
    return 0;
  }
};

export const charCount = (number: any, char: string) => {
  return String(toPlainString(number))
    .split(".")[1]
    .split("")
    .filter((m) => m === char).length;
};

export const charCountRange = (number: any, quantity: number, char: string) => {
  if (!number) return 0;

  const convertedNumber = String(toPlainString(number)).split(".");

  if (convertedNumber.length === 1) return 0;

  return convertedNumber[1]
    .split("")
    .slice(0, quantity)
    .every((m) => m === char);
};

export function getNumbersGreaterThanZero(digits: string) {
  return digits
    .split("")
    .map((n) => (n === "0" ? undefined : n))
    .reduce((previous, current) => {
      let numbers =
        (previous !== undefined ? previous : "") +
        (current !== undefined ? current : "");

      return numbers;
    }, "");
}

export function toPlainString(num: any) {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (_, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join("0") + c + d
        : b + c + d + Array(e - d.length + 1).join("0");
    }
  );
}

export const fullDateformatter = (date: Date) => {
  const lang = "en-US";
  const time = new Intl.DateTimeFormat(lang, { timeStyle: "short" }).format(
    date
  );
  const month = new Intl.DateTimeFormat(lang, { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat(lang, { day: "2-digit" }).format(date);
  const year = new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
  return `${time}, ${month} ${day}, ${year}`;
};

export const fullDateformatterForContract = (date: Date) => {
  const lang = "en-US";
  const time = new Intl.DateTimeFormat(lang, { timeStyle: "short" }).format(
    date
  );
  const month = new Intl.DateTimeFormat(lang, { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat(lang, { day: "2-digit" }).format(date);
  const year = new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
  return `${month} ${day}, ${year} at ${time}`;
};

export const shortDateformatter = (date: Date) => {
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(date);
  const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date);
  return `${month} ${day}, ${year}`;
};

export const shortDateformatterWithoutYear = (date: Date) => {
  const lang = "en-US";
  const month = new Intl.DateTimeFormat(lang, { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat(lang, { day: "2-digit" }).format(date);
  return `${month} ${day}`;
};

export const shortDateformatterWithDash = (date: Date) => {
  const lang = "en-US";
  const month = new Intl.DateTimeFormat(lang, { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat(lang, { day: "2-digit" }).format(date);
  const year = new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
  return `${day}-${month.toUpperCase()}-${year}`;
};

export const shortTimeformatter = (date: Date) => {
  const lang = "en-US";
  const time = new Intl.DateTimeFormat(lang, { timeStyle: "short" }).format(
    date
  );
  return `${time}`;
};

export function capitalize(word: string, onlyFirstLetter: boolean = false) {
  const lower = onlyFirstLetter
    ? word.substring(1, word.length)
    : word.toLowerCase().slice(1);
  return word.charAt(0).toUpperCase() + lower;
}

export const copyTextToClipboard = async (text: string) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  }
};

export function formatNumberDisplay(
  value: any,
  stylenumber: "decimal" | "percent" | "currency" | "unit" = "decimal",
  signDisplay?: "auto" | "exceptZero",
  maximumDigits?: number,
  minimunDigits?: number,
  avoidDecimalValue?: boolean
) {
  let formattedNumber = String("");
  const digitsAfterDecimal = decimalCount(toPlainString(value));

  const userLocale = getUserLocale();

  if (parseInt(toPlainString(value)) <= 0 && digitsAfterDecimal) {
    const zeros = charCount(value, "0");

    const hasMoreThanNineDecimals = digitsAfterDecimal > 9;
    const hasMoreThanNineZeros = charCountRange(value, 9, "0");

    let maximumFractionDigits =
      hasMoreThanNineDecimals && hasMoreThanNineZeros
        ? 0
        : zeros >= 20
          ? 20
          : maximumDigits;

    if (stylenumber === "percent") maximumFractionDigits = maximumDigits ?? 2;
 
    formattedNumber = new Intl.NumberFormat(userLocale, {
      style: stylenumber,
      signDisplay: signDisplay,
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: Math.min(minimunDigits ?? 0, maximumFractionDigits ?? 0),
      // TODO: change currency with the auth user
      currency: "USD",
    }).format(value);

    if (hasMoreThanNineDecimals && hasMoreThanNineZeros) {
      const digits = String(toPlainString(value)).split(".")[1];
      const decimals = getNumbersGreaterThanZero(digits)?.substring(0, 5);

      formattedNumber = `${formattedNumber}.0...0${decimals}`;
    }
  } else {
    if (avoidDecimalValue) {
      minimunDigits = 0;
      maximumDigits = 0;
    }

    formattedNumber = new Intl.NumberFormat(userLocale, {
      style: stylenumber,
      signDisplay: signDisplay,
      maximumFractionDigits:
        (maximumDigits ?? 0) === 0
          ? avoidDecimalValue
            ? 0
            : 2
          : maximumDigits,
      minimumFractionDigits:
        (minimunDigits ?? 0) === 0
          ? avoidDecimalValue
            ? 0
            : 2
          : minimunDigits,
      // TODO: change currency with the auth user
      currency: "USD",
    }).format(value);
    // if(stylenumber === "decimal" && !(formattedNumber % 1 > 0) ){
    //   return `${formattedNumber}.00`;
    // }
  }
  return isNaN(value) ? null : formattedNumber;
}

export const mappingLinkedAccountDeposit = (
  optionsList: any,
  limit: number = 2
) =>
  optionsList
    .filter(
      ({ statusDeposit, type }: { statusDeposit: string; type: string; }) =>
        (statusDeposit === "Enabled" || statusDeposit === "Ready") &&
        (type === BankType.WIRE ||
          type === BankType.PLAID ||
          type === BankType.BANK ||
          type === BankType.WIREINTDEPOSIT)
    )
    .map(
      ({
        bank,
        minimumDeposit,
        uid,
        type,
        name,
        accountLast,
      }: {
        bank: any;
        minimumDeposit: string;
        uid: string;
        type: string;
        name: string;
        accountLast: string;
      }) => {
        return {
          id: uid,
          label: name || bank.name,
          subtitle: "",
          icon: bank.logoUrl.includes(DefaultSettings.DEFAULT_BANK_IMAGE)
            ? "assets/svg/bank.svg"
            : bank.logoUrl,
          minimumDeposit,
          accountLast,
          uid,
          type,
        };
      }
    );

export const mappingLinkedAccountWithdraw = (optionsList: any) =>
  optionsList
    .filter(
      ({ statusWithdraw, type }: { statusWithdraw: string; type: string; }) =>
        (statusWithdraw === "Enabled" || statusWithdraw === "Ready") &&
        (type === BankType.WIRE ||
          type === BankType.PLAID ||
          type === BankType.BANK ||
          type === BankType.WIREINTWITHDRAW ||
          type === BankType.WIREWITHDRAW)
    )
    .map(
      ({
        bank,
        minimumDeposit,
        minimumWithdrawal,
        uid,
        type,
        name,
        accountLast,
      }: {
        bank: any;
        minimumDeposit: any;
        minimumWithdrawal: any;
        uid: string;
        type: string;
        name: string;
        accountLast: string;
      }) => {
        return {
          id: uid,
          label: name || bank.name,
          name: name || bank.name,
          country: bank.country,
          subtitle: "",
          icon: bank.logoUrl.includes(DefaultSettings.DEFAULT_BANK_IMAGE)
            ? "assets/svg/bank.svg"
            : bank.logoUrl,
          minimumDeposit,
          minimumWithdrawal,
          uid,
          type,
          accountLast,
        };
      }
    );
export const mappingAllOptions = (optionsList: any) =>
  optionsList
    .filter(({ active }: { active: boolean; }) => active)
    .map(({ name, description, depositDays, feetext, limit }: any) => {
      const id: string = name.replace("/", "").replace(/\s/g, "").toUpperCase();
      return {
        id,
        label: getText(description) || name,
        subtitle: getText(
          `${depositDays && depositDays.concat(" | ")} ${feetext && feetext.concat(" | ")
          } ${limit}`
        ),
        icon: IconsAllOptions[id],
      };
    });

export const mappingLinkedAccountProfile = (optionsList: any) =>
  optionsList.map(
    ({
      bank,
      minimumDeposit,
      uid,
      type,
      name,
      statusWithdraw,
      statusDeposit,
      accountLast,
      balanceLimit,
    }: {
      bank: any;
      minimumDeposit: string;
      uid: string;
      type: string;
      name: string;
      statusWithdraw: string;
      statusDeposit: string;
      accountLast: string;
      balanceLimit: any;
    }) => {
      return {
        id: uid,
        label: name || bank.name,
        subtitle: "",
        icon: bank.logoUrl.includes(DefaultSettings.DEFAULT_BANK_IMAGE)
          ? "assets/svg/bank.svg"
          : bank.logoUrl,
        minimumDeposit,
        uid,
        type,
        statusWithdraw,
        statusDeposit,
        accountLast,
        balanceLimit,
      };
    }
  );

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const handleClickOutsideTheModal = (
  modalClassName: string,
  closeModalCallback: { (): void; (): void; }
) => {
  document.addEventListener(
    "click",
    function (event) {
      if (!event?.target || !event?.target?.closest) return;
      if (
        String(event.target.tagName).toLocaleLowerCase() !== "button" &&
        String(event.target.tagName).toLocaleLowerCase() !== "input" &&
        String(event.target.tagName).toLocaleLowerCase() !== "svg" &&
        String(event.target.tagName).toLocaleLowerCase() !== "path" &&
        String(event.target.tagName).toLocaleLowerCase() !== "span" &&
        !event.target.closest(".input__container") &&
        !event.target.closest(".select-option") &&
        !event.target.closest(`.${modalClassName}`)
      ) {
        closeModalCallback();
      }
    },
    false
  );
};

export const sortingSearchBarAssets = (assets: Assets[]) => {
  const lista = assets
    .filter((asset) => asset?.assetType === DefaultSettings.NATIVE_CRYPTO)
    .sort(
      (a, b) => parseFloat(a?.midPriceAmount) - parseFloat(b?.midPriceAmount)
    )
    .splice(0, 500)
    .reverse();
  return lista;
};
export const getHashUrlAsKeyValue = (hashValue: String) => {
  return hashValue.split("&").reduce(function (res, item) {
    let parts = item.split("=");
    res[parts[0]] = parts[1];
    return res;
  }, {});
};

export const getRewardsProfileIcon = (rewardsProfile: string) => {
  return RewardsProfileIcon[rewardsProfile] || "NoTier";
};

export const getDecimalCount = (x: number) => {
  const n = x < 0 ? x * -1 : x;
  const text = n.toString();
  const index = text.indexOf(".");
  return index > 0 ? text.length - index - 1 : 0;
};
