import React from 'react'
import { PendingTransaction, useGetCustodyTransactionsPendingQuery } from 'state/store/custodyApi'
import { useCancelDefiTransactionRequestMutation } from 'state/store/investApi'
import styled from 'styled-components'
import { CustodyMainText, CustodyStyledTable } from './CustodyStyles'
import useAssets from 'shared/useAssets'
import LoadingBar from 'components/atoms/Loading/LoadingBar'
import { formatDate } from 'shared'
import ActionButton from './CustodyActionButton'
import CustodyInvestCancelModal from './CustodyInvestCancelModal'


const CustodyPendingActivity = () => {
  const pendingTransactionsQuery = useGetCustodyTransactionsPendingQuery(null, { pollingInterval: 30000})
  const pendingTransactions = pendingTransactionsQuery.data ?? []
  const isLoading = pendingTransactionsQuery.isLoading

  const [cancelDefiTransaction, cancelMeta] = useCancelDefiTransactionRequestMutation();
  const [cancelAbraTxUID, setCancelAbraTxUID] = React.useState<string | null>(null);
  const showCancelModalHandler = (abraTx: string) => {
    setCancelAbraTxUID(abraTx);
  }
  const confirmCancelHandler = () => {
    if (cancelAbraTxUID) {
      cancelDefiTransaction(cancelAbraTxUID);
    }
    setCancelAbraTxUID(null);
  }
  const closeCancelModalHandler = () => {
    setCancelAbraTxUID(null);
  }

  return (
  <div>
    <TokenSummaryTitle>Pending Activity</TokenSummaryTitle>
    {isLoading && <LoadingBar />}
    {!isLoading && pendingTransactions.length > 0 && (
      <CustodyStyledTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Transaction Id</th>
          <th>Network</th>
          <th>Quantity</th>
          <th>Action</th>
          <th>Status</th>
          <th>Strategy</th>
        </tr>
      </thead>
      <tbody>
        {!isLoading && pendingTransactions.map((record, _) =>
            <TokenSummaryRow key={record.abraTxUID} record={record}
              showCancelModalHandler={showCancelModalHandler} cancelLoading={cancelMeta.isLoading} />)}
      </tbody>
    </CustodyStyledTable>
    )}
    {!isLoading && pendingTransactions.length === 0 && (
      <p>Currently there are no pending transactions</p>
    )}
    <CustodyInvestCancelModal abraTxUID={cancelAbraTxUID} showModal={!!cancelAbraTxUID}
      onClose={closeCancelModalHandler} onConfirm={confirmCancelHandler} />

  </div>
  )
}

interface TokenSummaryRowParams {
  record: PendingTransaction
  showCancelModalHandler: (abraTx: string) => void
  cancelLoading: boolean
}

const TokenSummaryRow: React.FC<TokenSummaryRowParams> = ({record, showCancelModalHandler, cancelLoading}) => {
  const {getPriceFormattedI} = useAssets()
  const isDefi = (actionName: string) => {
    return actionName === 'Invest' || actionName === 'Divest';
  }
  const cancelButton = () => {
    let enabled = record.cancelable && isDefi(record.actionName);
    return (
      <ActionButton 
        hidden={!enabled}
        disabled={!enabled || cancelLoading}
        onClick={() => {enabled && showCancelModalHandler(record.abraTxUID)}}>
          Cancel
        </ActionButton>
    );
  }
  return (
    <tr>
      <td>
        <CustodyMainText>
          {formatDate(record.dateTime)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.abraTxUID}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.networkName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {getPriceFormattedI(record.amount.asset, record.amount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{record.actionName}</CustodyMainText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>
            {record.status}
            {cancelButton()}
          </CustodyMainText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{record.strategyName}</CustodyMainText>
        </TextGroup>
      </td>
    </tr>
  )
}


const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
`
export default CustodyPendingActivity
