import styled from "styled-components";

const Row = styled.div<{
  direction?: "row" | "column";
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  gap?: string;
  width?: string;
  height?: string;
  wrap?: string;
  flex?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
  ${({ wrap }) => (wrap ? `flex-wrap: ${wrap}` : "")};
  ${({ flex }) => (flex ? `flex: ${flex}` : "")};
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent}` : ""};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : "")};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}` : "")};
  ${({ margin }) => (margin ? `margin: ${margin}` : "")};
  ${({ gap }) => (gap ? `gap: ${gap}` : "")};
  ${({ width }) => (width ? `width: ${width}` : "")};
  ${({ height }) => (height ? `height: ${height}` : "")};
`;

export default Row;
