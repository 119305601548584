import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';

import useAssets from 'shared/useAssets';
import { TransactionYearMonth } from 'state/store/statementsApi';
import styled from 'styled-components';
import { CustodyMainText, CustodyStyledPrintableTable, CustodySubText } from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import InkindAssetRenderer from "../../../atoms/asset/InkindAssetRenderer";

const TransactionRow: React.FC<TransactionRowParams> = ({ transaction }) => {
const { getAssetByIdentifier } = useAssets();
const asset = getAssetByIdentifier(transaction.amount.asset)

return (
    <TransactionRowContainer>
      <td>
        <CustodyMainText>{moment.utc(transaction.dateTime).format('YYYY-MM-DD')}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{asset?.name}</CustodyMainText>
        <CustodySubText>{transaction.networkName}</CustodySubText>
      </td>
      <td>
        <CustodyMainText>{transaction.strategyName}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{transaction.actionName}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{transaction.transactionCategory}</CustodyMainText>
      </td>
      <td>
        <InkindAssetRenderer
            balance={transaction.amount}
        />
      </td>
      <td>
        <InkindAssetRenderer
            balance={transaction.networkFee}
        />
      </td>
      <td>
        <CustodyMainText>
          <CryptoAddressBox title={transaction.blockchainHash}>
            <CryptoAddress>{transaction.blockchainHash}</CryptoAddress>
          </CryptoAddressBox>
        </CustodyMainText>
      </td>
      <td>{transaction.abraTxUID}</td>
    </TransactionRowContainer>
  );
};

const TransactionRowContainer = styled.tr``;

const CustodyStatementTransaction = () => {
  const { month, year } = useParams();
  const { transactions } = useCustodyStatement({ year, month });
  return (
    <div>
      <TransactionsTitle>Transaction Details</TransactionsTitle>
      <div>
        <CustodyStyledPrintableTable>
          <thead>
            <tr>
              <th>Date</th>
              <th>Asset</th>
              <th>Strategy</th>
              <th>Action</th>
              <th>Type</th>
              <th>Quantity</th>
              <th>Network Fee</th>
              <th>Blockchain Id</th>
              <th>Transaction Id</th>
            </tr>
          </thead>
          <tbody>
            {transactions.data?.map((record, index) => (
              <TransactionRow key={index} transaction={record} />
            ))}
          </tbody>
        </CustodyStyledPrintableTable>
      </div>
    </div>
  );
};

interface TransactionRowParams {
  transaction: TransactionYearMonth;
}

const TransactionsTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  margin-top: 6vh;
  letter-spacing: -0.5px;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;

const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 6vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  font-size: 80%;
  overflow: hidden;
`;

export default CustodyStatementTransaction;
