import React from 'react';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/templates/Layout';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';

const CustodyTax: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Layout customGrid={alternativeGridSystem}>
            <MarginContainer>
                <CustodyNavigationWidget>
                <h1>Tax form</h1>
                </CustodyNavigationWidget>
            </MarginContainer>
        </Layout>
    );
};

export default CustodyTax;
