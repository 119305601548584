import { emptySplitApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAll: build.query<GetAllApiResponse, GetAllApiArg>({
      query: () => ({ url: `/user` }),
    }),
    addUser: build.mutation<AddUserApiResponse, AddUserApiArg>({
      query: (queryArg) => ({
        url: `/user`,
        method: 'POST',
        body: queryArg.createUserRequest,
      }),
    }),
    updatePassword: build.mutation<UpdatePasswordApiResponse, UpdatePasswordApiArg>({
      query: (queryArg) => ({
        url: `/user/update-password`,
        method: 'POST',
        body: queryArg.updatePasswordRequest,
      }),
    }),
    unblockAccount: build.mutation<UnblockAccountApiResponse, UnblockAccountApiArg>({
      query: (queryArg) => ({
        url: `/user/unblock/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
      query: (queryArg) => ({
        url: `/user/reset-password/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    resetMfa: build.mutation<ResetMfaApiResponse, ResetMfaApiArg>({
      query: (queryArg) => ({
        url: `/user/reset-mfa/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    blockAccount: build.mutation<BlockAccountApiResponse, BlockAccountApiArg>({
      query: (queryArg) => ({
        url: `/user/block/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    getApiKey: build.query<GetApiKeyApiResponse, GetApiKeyApiArg>({
      query: () => ({ url: `/user/apikey` }),
      providesTags: ['ApiKeys'],
    }),
    getUsersFromOrganization: build.query<UsersFromOrganizationResponse, void>({
      query: () => ({ url: '/organization/users' }),
    }),
    createApiKey: build.mutation<CreateApiKeyApiResponse, CreateApiKeyApiArg>({
      query: () => ({ url: `/user/apikey`, method: 'POST' }),
    }),
    sendTransactionReportByEmail: build.mutation<SendTransactionReportByEmailApiResponse, SendTransactionReportByEmailApiArg>({
      query: (queryArg) => ({
        url: `/transaction/send/report/${queryArg['type']}`,
        method: 'POST',
        body: queryArg.datesReportRequest,
      }),
    }),
    validateMfa: build.mutation<ValidateMfaApiResponse, ValidateMfaApiArg>({
      query: (queryArg) => ({
        url: `/public/validate`,
        method: 'POST',
        body: queryArg.mfaAuthenticationRequest,
      }),
      transformResponse(response, meta) {
        return { accessToken: meta?.response?.headers.get('authorization') };
      },
    }),
    setupPassword: build.mutation<SetupPasswordApiResponse, SetupPasswordApiArg>({
      query: (queryArg) => ({
        url: `/public/setup-password`,
        method: 'POST',
        body: queryArg.setupPasswordRequest,
      }),
    }),
    setupAuthentication: build.mutation<SetupPasswordApiResponse, SetupAuthenticationRequest>({
      query: (body) => ({
        url: `/custody-user/individual/auth`,
        method: 'POST',
        body,
      }),
    }),
    reset2Fa: build.mutation<Reset2FaApiResponse, Reset2FaApiArg>({
      query: (queryArg) => ({
        url: `/public/reset2fa`,
        method: 'POST',
        body: queryArg.usernameAndPasswordAuthenticationRequest,
      }),
    }),
    resetPassword1: build.mutation<ResetPassword1ApiResponse, ResetPassword1ApiArg>({
      query: (queryArg) => ({
        url: `/public/reset-password`,
        method: 'POST',
        body: queryArg.emailRequest,
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/public/login`,
        method: 'POST',
        body: queryArg.usernameAndPasswordAuthenticationRequest,
      }),
    }),
    changeOrganization: build.mutation<GetUserInformationApiResponse, number>({
      query: (organizationId) => ({
        url: `/user/organization/${organizationId}`,
        method: 'PATCH',
      }),
    }),
    blockAccount1: build.mutation<BlockAccount1ApiResponse, BlockAccount1ApiArg>({
      query: (queryArg) => ({
        url: `/public/block`,
        method: 'POST',
        body: queryArg.usernameAndPasswordAuthenticationRequest,
      }),
    }),
    downloadAttachment: build.mutation<DownloadAttachmentApiResponse, DownloadAttachmentApiArg>({
      query: (queryArg) => ({
        url: `/order/${queryArg.orderId}/download/${queryArg.attachmentId}`,
        method: 'POST',
      }),
    }),
    addProduct: build.mutation<AddProductApiResponse, AddProductApiArg>({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/${queryArg.product}`,
        method: 'POST',
      }),
    }),
    removeProduct: build.mutation<RemoveProductApiResponse, RemoveProductApiArg>({
      query: (queryArg) => ({
        url: `/organization/${queryArg.organizationId}/${queryArg.product}`,
        method: 'DELETE',
      }),
    }),
    deleteAccount: build.mutation<DeleteAccountApiResponse, DeleteAccountApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.updateUserRequest,
      }),
    }),
    getUserInformation: build.query<GetUserInformationApiResponse, GetUserInformationApiArg>({
      query: () => ({ url: `/user/information` }),
    }),
    getTransactions: build.query<GetTransactionsApiResponse, GetTransactionsApiArg>({
      query: (queryArg) => ({
        url: `/transaction/${queryArg['type']}`,
        params: {
          orderId: queryArg.orderId,
          sortField: queryArg.sortField,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    getOrders: build.query<GetOrdersApiResponse, GetOrdersApiArg>({
      query: (queryArg) => ({
        url: `/order`,
        params: {
          type: queryArg['type'],
          status: queryArg.status,
          sortField: queryArg.sortField,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    getOrderDetails: build.query<GetOrderDetailsApiResponse, GetOrderDetailsApiArg>({
      query: (queryArg) => ({ url: `/order/${queryArg.orderId}` }),
    }),
    getPaymentSchedule: build.query<GetPaymentScheduleApiResponse, GetPaymentScheduleApiArg>({
      query: (queryArg) => ({
        url: `/order/${queryArg.orderId}/payment-schedule`,
        params: {
          sortField: queryArg.sortField,
          sortDirection: queryArg.sortDirection,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    getCompoundSchedule: build.query<GetCompoundScheduleApiResponse, GetCompoundScheduleApiArg>({
      query: (queryArg) => ({
        url: `/order/${queryArg.orderId}/compound-schedule`,
      }),
    }),
    getLoanSummary: build.query<GetLoanSummaryApiResponse, GetLoanSummaryApiArg>({
      query: () => ({ url: `/order/loan/details` }),
    }),
    getOrdersSummary: build.query<GetOrdersSummaryApiResponse, GetOrdersSummaryApiArg>({
      query: () => ({ url: `/order/deposit/summary` }),
    }),
    getAllOrganizations: build.query<GetAllOrganizationsApiResponse, GetAllOrganizationsApiArg>({
      query: () => ({ url: `/organization` }),
    }),
    getAssets: build.query<GetAssetsApiResponse, GetAssetsApiArg>({
      query: () => ({ url: `/asset` }),
    }),
    getPrices: build.query<GetPricesApiResponse, GetPricesApiArg>({
      query: () => ({ url: `/asset/prices` }),
    }),
    deleteApiKey: build.mutation<DeleteApiKeyApiResponse, DeleteApiKeyApiArg>({
      query: (queryArg) => ({
        url: `/user/apikey/${queryArg.apiKeyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ApiKeys'],
    }),
  }),
  overrideExisting: false,
});

export interface SetupAuthenticationRequest {
  email: string,
  password: string,
  registrationId: string,
  smaAccountId: string
}
export { injectedRtkApi as api };
export type GetAllApiResponse = /** status 200 Retrieve the list of users */ UsersResponse;
export type GetAllApiArg = void;
export type AddUserApiResponse = /** status 200 Create a new user */ PrimeUser;
export type AddUserApiArg = {
  createUserRequest: CreateUserRequest;
};
export type UpdatePasswordApiResponse = /** status 200 User password updated successfully */ Blob;
export type UpdatePasswordApiArg = {
  updatePasswordRequest: UpdatePasswordRequest;
};
export type UnblockAccountApiResponse = unknown;
export type UnblockAccountApiArg = {
  userId: number;
};
export type ResetPasswordApiResponse = /** status 200 Reset password process successfully required */ PrimeUser;
export type ResetPasswordApiArg = {
  userId: number;
};
export type ResetMfaApiResponse = unknown;
export type ResetMfaApiArg = {
  userId: number;
};
export type BlockAccountApiResponse = unknown;
export type BlockAccountApiArg = {
  userId: number;
};
export type GetApiKeyApiResponse = /** status 200 Get all Talos ApiKeys for logged user */ TalosApiKeyResponse;
export type GetApiKeyApiArg = void;
export type CreateApiKeyApiResponse = /** status 200 Create a ApiKey for Talos */ TalosApiKeyResponse;
export type CreateApiKeyApiArg = void;
export type SendTransactionReportByEmailApiResponse = /** status 200 Successfully Sending Report by Email */ AssetResponse;
export type SendTransactionReportByEmailApiArg = {
  type: string;
  datesReportRequest: DatesReportRequest;
};
export type ValidateMfaApiResponse = unknown;
export type ValidateMfaApiArg = {
  mfaAuthenticationRequest: MfaAuthenticationRequest;
};
export type SetupPasswordApiResponse = /** status 200 New password setup successfully */ undefined;
export type SetupPasswordApiArg = {
  setupPasswordRequest: SetupPasswordRequest;
};
export type Reset2FaApiResponse = unknown;
export type Reset2FaApiArg = {
  usernameAndPasswordAuthenticationRequest: UsernameAndPasswordAuthenticationRequest;
};
export type ResetPassword1ApiResponse = /** status 200 Password request successful */ undefined;
export type ResetPassword1ApiArg = {
  emailRequest: EmailRequest;
};
export type LoginApiResponse = /** status 200 User logged in successfully */ LoginResponse;
export type LoginApiArg = {
  usernameAndPasswordAuthenticationRequest: UsernameAndPasswordAuthenticationRequest;
};

export type BlockAccount1ApiResponse = unknown;
export type BlockAccount1ApiArg = {
  usernameAndPasswordAuthenticationRequest: UsernameAndPasswordAuthenticationRequest;
};
export type DownloadAttachmentApiResponse = /** status 200 Initiate the download of requested file */ string;
export type DownloadAttachmentApiArg = {
  orderId: number;
  attachmentId: number;
};
export type AddProductApiResponse = /** status 200 Add product to organization */ OrganizationResponse;
export type AddProductApiArg = {
  organizationId: number;
  product: string;
};
export type RemoveProductApiResponse = /** status 200 Remove product from a organization */ OrganizationResponse;
export type RemoveProductApiArg = {
  organizationId: number;
  product: string;
};
export type DeleteAccountApiResponse = unknown;
export type DeleteAccountApiArg = {
  userId: number;
};
export type UpdateUserApiResponse = /** status 200 Update an existing user */ PrimeUser;
export type UpdateUserApiArg = {
  userId: number;
  updateUserRequest: UpdateUserRequest;
};
export type GetUserInformationApiResponse = PrimeUser;
export type GetUserInformationApiArg = void;
export type GetTransactionsApiResponse = /** status 200 OK */ TransactionsResponse;
export type GetTransactionsApiArg = {
  type: string;
  /** Order Id */
  orderId?: number;
  /** Sort Field */
  sortField?: string;
  /** Sort Direction */
  sortDirection?: string;
  /** Page number */
  page?: string;
  /** Number of records per page */
  size?: string;
};
export type GetOrdersApiResponse = /** status 200 Retrieve the list of orders */ OrdersResponse;
export type GetOrdersApiArg = {
  /** Type of the orders */
  type?: string;
  /** Status of the orders one or many */
  status?: string[];
  /** Sort Field */
  sortField?: string;
  /** Sort Direction */
  sortDirection?: string;
  /** Page number */
  page?: string;
  /** Number of records per page */
  size?: string;
};
export type GetOrderDetailsApiResponse = /** status 200 Retrieve the detailed info about orders */ OrderResponse;
export type GetOrderDetailsApiArg = {
  orderId: number;
};
export type GetPaymentScheduleApiResponse = /** status 200 Retrieve repayment schedule */ LoanPaymentScheduleResponse;
export type GetPaymentScheduleApiArg = {
  orderId: number;
  /** Sort Field */
  sortField?: string;
  /** Sort Direction */
  sortDirection?: string;
  /** Page number */
  page?: string;
  /** Number of records per page */
  size?: string;
};
export type GetCompoundScheduleApiResponse = /** status 200 Retrieve the compound schedule for a given loan */ CompoundScheduleControllerResponse;
export type GetCompoundScheduleApiArg = {
  orderId: number;
};
export type GetLoanSummaryApiResponse = /** status 200 Retrieve loan summary */ LoanSummaryResponse;
export type GetLoanSummaryApiArg = void;
export type GetOrdersSummaryApiResponse = /** status 200 Retrieve the summary info about orders by type */ OrdersSummaryResponse;
export type GetOrdersSummaryApiArg = void;
export type GetAllOrganizationsApiResponse = /** status 200 Retrieve the list of organizations */ OrganizationsResponse;
export type GetAllOrganizationsApiArg = void;
export type GetAssetsApiResponse = /** status 200 Successfully retrieve assets */ AssetResponse;
export type GetAssetsApiArg = void;
export type GetPricesApiResponse = /** status 200 Successfully retrieve assets prices */ PricesResponse;
export type GetPricesApiArg = void;
export type DeleteApiKeyApiResponse = /** status 200 Delete given ApiKey id on Talos */ Blob;
export type DeleteApiKeyApiArg = {
  apiKeyId: string;
};
export type Organization = {
  id?: number;
  name?: string;
  id1Token?: string;
  registerEmail?: string;
  status?: string;
  alias?: string;
  department?: string;
  documentNumber?: string;
  products?: string[];
  owner?: boolean;
  smaAccountNumber?: string
};
export type PrimeUser = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  organization?: Organization;
  organizations: SimplifiedOrganizationInformation[];
};

export type SimplifiedOrganizationInformation = {
  owner: boolean;
  organizationId: number;
  alias: string;
};

export type UsersResponse = {
  users?: PrimeUser[];
};
export type ApiError = {
  code?: number;
  message?: string;
};
export type CreateUserRequest = {
  organizationID?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
};
export type UpdatePasswordRequest = {
  oldPassword?: string;
  newPassword?: string;
};
export type TalosApiKeyResponse = {
  apiKey?: TalosApiKeyDto;
};
export type TalosApiKeyDto = {
  customerUserApiKeyID?: string;
  apiKey?: string;
  apiSecret?: string;
  permissions?: string[];
};
export type AssetDto = {
  identifier?: string;
  icon?: string;
  type?: string;
  name?: string;
  longName?: string;
  symbol?: string;
  fractionDigits?: number;
  minimumViableUnit?: string;
  apy?: string;
};
export type AssetResponse = {
  assets?: AssetDto[];
};
export type DatesReportRequest = {
  dateFrom?: string;
  dateTo?: string;
};
export type MfaAuthenticationRequest = {
  mfaToken?: string;
  otpCode?: string;
};
export type SetupPasswordRequest = {
  email?: string;
  password?: string;
  token?: string;
};
export type UsernameAndPasswordAuthenticationRequest = {
  username?: string;
  password?: string;
};
export type EmailRequest = {
  email?: string;
};
export type LoginResponse = {
  accessToken?: string;
  barcodeUri?: string;
  secret?: string;
  otpIsActive?: boolean;
};
export type OrganizationResponse = {
  organization?: Organization;
};
export type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
};

export type BusinessException = {
  cause?: {
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      nativeMethod?: boolean;
      className?: string;
    }[];
    message?: string;
    suppressed?: {
      stackTrace?: {
        classLoaderName?: string;
        moduleName?: string;
        moduleVersion?: string;
        methodName?: string;
        fileName?: string;
        lineNumber?: number;
        nativeMethod?: boolean;
        className?: string;
      }[];
      message?: string;
      localizedMessage?: string;
    }[];
    localizedMessage?: string;
  };
  stackTrace?: {
    classLoaderName?: string;
    moduleName?: string;
    moduleVersion?: string;
    methodName?: string;
    fileName?: string;
    lineNumber?: number;
    nativeMethod?: boolean;
    className?: string;
  }[];
  message?: string;
  suppressed?: {
    stackTrace?: {
      classLoaderName?: string;
      moduleName?: string;
      moduleVersion?: string;
      methodName?: string;
      fileName?: string;
      lineNumber?: number;
      nativeMethod?: boolean;
      className?: string;
    }[];
    message?: string;
    localizedMessage?: string;
  }[];
  localizedMessage?: string;
};
export type TransactionDto = {
  id?: number;
  orderId?: number;
  transactionType?: string;
  date?: string;
  currency?: string;
  initialAmount?: string;
  transactionAmount?: string;
  operationId?: number;
};
export type PaginationResponse = {
  totalRecords?: number;
  nextPage?: number;
  previousPage?: number;
  totalPages?: number;
};
export type TransactionsResponse = {
  transactions?: TransactionDto[];
  paginationResponse?: PaginationResponse;
};
export type CollateralDto = {
  asset?: string;
  amount?: string;
  distribution?: string;
  moreInfo?: string;
  moreInfoAlias?: string;
  price?: string;
  type?: string;
  unitPrice?: string;
  vu?: string;
};
export type AttachmentDto = {
  id?: number;
  alias?: string;
  filename?: string;
};
export type RepaymentDetailDto = {
  dueDate?: string;
  interestAmountExpected?: string;
  interestAmountPaid?: string;
  paymentDate?: string;
  status?: string;
};
export type LoanRepaymentDto = {
  finalPaymentDate?: string;
  nextPayment?: RepaymentDetailDto;
  totalInterestPaid?: string;
};
export type OrderDto = {
  id?: number;
  id1Token?: string;
  startDate?: string;
  initialPrincipal?: string;
  currency?: string;
  interestRate?: string;
  interestType?: string;
  termType?: string;
  type?: string;
  days?: number;
  maturity?: string;
  interestAccrued?: string;
  currentPrincipal?: string;
  status?: string;
  closeDate?: string;
  repaymentMethod?: string;
  principalPayable?: string;
  interestPayable?: string;
  currentLtv?: string;
  marginLineLtv?: string;
  riskyLtv?: string;
  warningLtv?: string;
  paidPrincipal?: string;
  paidInterest?: string;
  collateral?: CollateralDto[];
  attachments?: AttachmentDto[];
  loanRepayment?: LoanRepaymentDto;
  balance?: string;
};
export type OrdersResponse = {
  orders?: OrderDto[];
  paginationResponse?: PaginationResponse;
};
export type OrderResponse = {
  order?: OrderDto;
};
export type PaymentResponse = {
  dueDate?: string;
  paymentDate?: string;
  interestAmountPaid?: string;
  interestAmountExpected?: string;
  currency?: string;
  status?: string;
};
export type LoanPaymentScheduleResponse = {
  payments?: PaymentResponse[];
  paginationResponse?: PaginationResponse;
};
export type CompoundScheduleInfoDto = {
  currency?: string;
  beginPrincipal?: string;
  closePrincipal?: string;
  dueDate?: string;
  paidInterest?: string;
  paymentDate?: string;
  status?: string;
  planInterest?: string;
};
export type CompoundScheduleControllerResponse = {
  data?: CompoundScheduleInfoDto[];
};
export type LoanSummaryData = {
  currency?: string;
  outstandingBalance?: string;
  principalBorrowed?: string;
  interestOutstanding?: string;
  collateral?: string;
  averageLtv?: string;
  openLoans?: number;
  principalOutstanding?: string;
};
export type LoanSummaryResponse = {
  data?: LoanSummaryData[];
  averageLtv?: string;
};
export type AssetSummaryDto = {
  asset?: string;
  amount?: string;
  initialPrincipalDeposit?: string;
  interestAccruedYearToDate?: string;
  currentPrincipal?: string
  openOrders?: number;
};
export type OrdersSummaryResponse = {
  openOrders?: number;
  assets?: AssetSummaryDto[];
};
export type OrganizationsResponse = {
  organizations?: Organization[];
};
export type PricesDto = {
  identifier?: string;
  priceChangePercentage?: string;
  price?: string;
  marketcap?: string;
  volume?: string;
};
export type UsersFromOrganizationResponse = {
  users: UsersFromOrganization[];
};
export type UsersFromOrganization = {
  userId: number;
  email: string;
  status: string;
  owner: boolean;
};
export type PricesResponse = {
  prices?: PricesDto[];
};
export const {
  useGetAllQuery,
  useChangeOrganizationMutation,
  useAddUserMutation,
  useUpdatePasswordMutation,
  useUnblockAccountMutation,
  useResetPasswordMutation,
  useResetMfaMutation,
  useBlockAccountMutation,
  useGetApiKeyQuery,
  useCreateApiKeyMutation,
  useSendTransactionReportByEmailMutation,
  useValidateMfaMutation,
  useSetupPasswordMutation,
  useReset2FaMutation,
  useResetPassword1Mutation,
  useLoginMutation,
  useBlockAccount1Mutation,
  useDownloadAttachmentMutation,
  useAddProductMutation,
  useRemoveProductMutation,
  useDeleteAccountMutation,
  useUpdateUserMutation,
  useGetUserInformationQuery,
  useGetTransactionsQuery,
  useGetOrdersQuery,
  useGetOrderDetailsQuery,
  useGetPaymentScheduleQuery,
  useGetCompoundScheduleQuery,
  useGetLoanSummaryQuery,
  useGetOrdersSummaryQuery,
  useGetAllOrganizationsQuery,
  useGetAssetsQuery,
  useGetPricesQuery,
  useGetUsersFromOrganizationQuery,
  useDeleteApiKeyMutation,
  useSetupAuthenticationMutation
} = injectedRtkApi;
