import { useEffect } from 'react';
import { useState } from 'react';
import { useGetCustodyBalanceWithdrawQuery, useGetCustodyWithdrawAssetsQuery } from 'state/store/custodyApi'
import useAssets from 'shared/useAssets'
import useNetworks from 'pages/WhitelistedAddresses/useNetworks'

const useCustodyWithdraw = () => {
  const {withdrawalAsset, setWithdrawalAsset, loadingNetworks, custodyWithdrawalNetworks, selectedWithdrawalNetwork, restart, custodyAssetNetworkInformation, loadingWallet, maxUnverifiedWithdraw, minWithdraw} = useNetworks({boost: false, custodyWithdraw: true, custodyDeposit: false})
  const [availableAssets, setAvailableAssets] = useState<string[]>([])
  const { getAssetByIdentifier } = useAssets()
  const custodyWithdrawAssets = useGetCustodyWithdrawAssetsQuery(null)
  const assetInformation = getAssetByIdentifier(withdrawalAsset)
  const feeAsset = getAssetByIdentifier(custodyAssetNetworkInformation?.availableAmountInFeeAsset?.asset)
  const balances = useGetCustodyBalanceWithdrawQuery();
  useEffect(() => {
    setAvailableAssets(custodyWithdrawAssets.data ?? [])
  }, [custodyWithdrawAssets])

  return {
    selectedAsset: assetInformation,
    selectedFeeAsset: feeAsset,
    setSelectedAsset: setWithdrawalAsset,
    availableAssets,
    selectedNetwork: selectedWithdrawalNetwork,
    custodyAssetNetworkInformation,
    networks: custodyWithdrawalNetworks,
    loadingNetworks,
    balances,
    loadingWallet,
    maxUnverifiedWithdraw,
    minWithdraw,
    restart,
  }
}

export default useCustodyWithdraw
