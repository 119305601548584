import { Percentage } from 'lib/utils/types'
import React from 'react'
import { useParams } from 'react-router-dom'
import getUsDollar from 'shared/dollarFormat'
import { DefiBalanceStatementRow, useGetStatementDefiBalancesQuery } from 'state/store/statementsApi'
import styled from 'styled-components'
import { CustodyStyledPrintableTable } from '../CustodyStyles'

import InkindAssetRenderer from "../../../atoms/asset/InkindAssetRenderer"

const CustodyStatementInvestmentSummary = () => {

  const { month, year } = useParams();
  const yearMonth = {year, month: month?.padStart(2, '0')}
  const statementDefiBalance = useGetStatementDefiBalancesQuery(yearMonth)
  
  const data = statementDefiBalance.data ?? []
  return (
  <div>
    <TokenSummaryTitle>Investment Summary</TokenSummaryTitle>
    <CustodyStyledPrintableTable>
      <thead>
        <tr>
          <th>Strategy Name</th>
          <th>Period Ending Balance</th>
          <th>Income (YTD)</th>
          <th>Cost Basis</th>
          <th>Unrealized Gain / Loss*</th>
          <th>Realized Gain / Loss (YTD)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((record, index) => <CustodySummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledPrintableTable>
  </div>
  )
}

interface CustodySummaryRowProps {
  record: DefiBalanceStatementRow
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  return (
    <tr>
      <td>
        <MainRowText>
          {record.strategyName}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
            <InkindAssetRenderer
                balance={record.endingBalance}
                value={record.endingValue}
            />
        </MainRowText>
      </td>
      <td>
        <MainRowText>
            <InkindAssetRenderer
                balance={record.yieldYTD} 
                value={record.incomeYTD}
            />
        </MainRowText>
      </td>
      <td>
      <MainRowText>{getUsDollar(record.costBasis?.amount ?? '0')}</MainRowText>
      </td>
      
      <td>
        <GainLossArea 
          loss={parseFloat(record.unrealizedAmount?.amount ?? '0') < 0}>
            {getUsDollar(record.unrealizedAmount?.amount ?? '0')} ({new Percentage(record.unrealizedPercentage).print()})
        </GainLossArea>
      </td>
        <td>
            <MainRowText>
                <GainLossArea 
                  loss={parseFloat(record.realizedYTD?.amount ?? '0') < 0}>
                    {getUsDollar(record.realizedYTD?.amount ?? '0')} 
                </GainLossArea>
            </MainRowText>
        </td>
    </tr>
  )
}

interface GainLossProps {
  loss: boolean
}
const GainLossArea = styled.span<GainLossProps>`
  ${p => p.loss ? 'color: #EC396B;' : ''}
`

const MainRowText = styled.span`
`

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`
export default CustodyStatementInvestmentSummary
