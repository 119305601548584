import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import RadioButton from 'components/atoms/RadioButton';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { emailValidation, symbolsValidation } from 'shared';
import { CustodyUserRequest } from 'state/store/onboardingApi';
import styled from 'styled-components';

interface Props {
  next: () => void;
  information: CustodyUserRequest;
  setInformation: (email: CustodyUserRequest) => void;
}
const RegisterBasicInformation: FC<Props> = ({ next, information, setInformation }) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  const validateEmail = (email: string) => {
    if (!symbolsValidation('@', email)) {
      setEmailError(true);
    } else if (!emailValidation(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmailError(!symbolsValidation('@', email) && !emailValidation(email));
  };

  return (
    <RegisterBasicInformationBox>
      <EmailBox>
        <p>Please provide your email address</p>
        <Input
          label='Email'
          className='input'
          inputProps={{
            value: information.email,
            id: 'email',
            name: 'email',
            type: 'email',
            onChange: (event: any) => {
              validateEmail(event?.target?.value);
              setEmailErrorMessage('');
              setInformation({ ...information, email: event.target.value ?? '' });
            },
            onBlur: (event: any) => {
              setEmailErrorMessage(emailError ? 'invalid email format' : '');
              validateEmail(event?.target?.value);
            },
          }}
          errorMessage={emailErrorMessage}
          dismissAlert={() => setEmailErrorMessage('')}
          dismissAlertButton={false}
        />
      </EmailBox>
      <div>
        <RadioGroup>
          <p>Are you a U.S. Resident or Tax Payer?</p>
          <RadioButton
            size={30}
            name='usResident'
            text='Yes'
            value='Yes'
            checked={information.usResident}
            onClick={() => setInformation({ ...information, usResident: true })}
          />
          <RadioButton
            name='usResident'
            text='No'
            value='No'
            checked={!information.usResident}
            onClick={() => setInformation({ ...information, usResident: false })}
          />
          <p>Are you an Individual or an Organization?</p>
          <RadioButton
            size={30}
            name='clientType'
            text='Individual'
            value='Individual'
            checked={information.organizationType === 'INDIVIDUAL'}
            onClick={() => setInformation({ ...information, organizationType: 'INDIVIDUAL' })}
          />
          <RadioButton
            name='clientType'
            text='Organization'
            value='INSTITUTION'
            checked={information.organizationType === 'INSTITUTION'}
            onClick={() => setInformation({ ...information, organizationType: 'INSTITUTION' })}
          />
        </RadioGroup>
      </div>
      <Grid>
        <Button buttonType={TypesNamesEnum.SECONDAY} label='Back' onClick={back} type='button' />
        <Button buttonType={TypesNamesEnum.ACCENT} label='Next' onClick={next} type='submit' disabled={!!emailError || !information.email} />
      </Grid>
    </RegisterBasicInformationBox>
  );
};

const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;
const RegisterBasicInformationBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const EmailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap:15px;
`
const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2vh;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
`;

export default RegisterBasicInformation;
