import styled from 'styled-components'

interface CustodyStylesTableProps {
  blur?: boolean
}
export const CustodyStyledTable = styled.table<CustodyStylesTableProps>`
  ${props => props.blur ? 'filter: blur(4px);' : ''}
  margin-top: 2vh;
  width: 100%;
  border-collapse: separate;
  @media print {
      font-weight: 600;
    }
  tbody tr {
    background-color: rgba(0,0,0,0.1);
    margin-top: 2px;
    padding: 0;
    @media print {
      background-color: white;
    }
    &:hover {
      @media print {
      background-color: white;
    }
      border-radius:8px;
      overflow: hidden;
    }
  }
  td {
    padding: 0.5vw 1vw 0.5vw 0;
    font-size: calc(min(1.5vw, 14px));
    @media print {
      color: black;
      font-weight: 600;
    }
  }
  th {
    font-weight: 400;
    color: rgba(255,255,255,0.7);
    font-size: 12px;
    text-align: left;
    padding-right: 30px;
    @media print {
      color: black;
      font-weight: 800;
    }
  }
  
`

export const CustodyStyledPrintableTable = styled.table<CustodyStylesTableProps>`
  ${props => props.blur ? 'filter: blur(4px);' : ''}
  margin-top: 2vh;
  max-width: 100vw;
  padding: 6px;
  table-layout:fixed;
  border-collapse: separate;
  tbody tr {
    background-color: rgba(0,0,0,0.1);
    margin-top: 2px;
    padding: 0;
    &:hover {
      background-color: rgba(0,0,0,0.2);
      border-radius:8px;
      overflow: hidden;
    }
  }
  td {
    padding: 0.5vw 1vw 0.5vw 0;
    font-size: calc(min(1.5vw, 14px));
    word-wrap:break-word;
    border-top: 1px solid #9ba7b6;
    border-collapse: collapse;
    border-spacing: 0px;
    margin-right: 2px;
    border-right: 2px solid #9ba7b655;
    border-collapse: collapse;
  }
  th {
    font-weight: 400;
    color: rgba(255,255,255,0.7);
    font-size: 12px;
    text-align: left;
    color: #d2d3d4;
    text-align: left;
    font-size: xx-small;
    background-color: rgb(50, 18, 80);
    border-top: 1px solid #9ba7b6;
    border-collapse: collapse;
    padding: 6px;
  }
  @media print {
    border-collapse: separate;
    color: black !important;
    td, span {
      color: black !important;
    }
    td { 
      border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    th {
      color: black !important;
      font-weight: bold !important;
    }
  }
`

export const CustodyMainText = styled.span`
  display: flex;
`

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #9f7fff;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 4px;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`
export const CustodySubText = styled.span`
  font-weight: 400;
  color: #FFFFFF;
  font-size: 80%;
  opacity: 0.7;
  cursor: inherit;
`
