import { TypesNamesEnum } from '@types/enums/Button.enum';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';
import _ from 'lodash';
import Button from 'components/atoms/Button';
import Loading from 'components/atoms/Loading';
import Select from 'components/molecules/Select';
import Modal from 'components/organisms/Modal';
import Layout from 'components/templates/Layout';
import Widget from 'components/templates/Widget';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import AddressBox from 'pages/Withdraw/AddressBox';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { networkSelector } from 'state/slice/networksReducer';
import { AssetDto, useGetOrdersSummaryQuery } from 'state/store/api';
import { TransferAddress, useCreateAddressMutation, useDeleteAddressMutation, useGetWhitelistAddressesQuery } from 'state/store/withdrawApi';
import styled from 'styled-components';
import Networks from './Networks';
import useWithdrawRequest from './useWithdrawRequest';
import { toast, ToastType } from 'components/organisms/Toast';
import { PagesUrl } from 'lib/constants/config.constant';
import useCustodyWithdraw from 'components/pages/custody/extraCustodyHooks/useCustodyWithdraw'
import useAssets from 'shared/useAssets'

export interface AssetWhitelistAddressInformation {
  asset?: AssetDto
  isBoost: boolean
  isCustody: boolean
}

const WhitelistedAddresses = () => {
  const { availableAssets, setSelectedAsset, selectedAsset, restart } = useCustodyWithdraw()
  const assetsHook = useAssets()
  const ordersSummary = useGetOrdersSummaryQuery();
  const [addresses, setAddresses] = useState<TransferAddress[]>([]);
  const whitelistAddresses = useGetWhitelistAddressesQuery(null, { pollingInterval: 120000, refetchOnMountOrArgChange: true });
  const [boostAssets, setBoostAssets] = useState<string[]>([]);
  const [custodyAssets, setCustodyAssets] = useState<string[]>([])
  const [assets, setAssets] = useState<AssetWhitelistAddressInformation[]>([])
  const [asset, setAsset] = useState<AssetWhitelistAddressInformation>()
  const { assetName, withdrawId } = useParams();
  const [showAddAddress, setShowAddAddress] = useState(assetName ? true : false);
  const { selectedWithdrawalNetwork: selectedNetwork , loadingNetworks } = useSelector(networkSelector);
  const [deleteAddress, deleteAddressStatus] = useDeleteAddressMutation();
  const [inputAddress, setInputAddress] = useState<string>('');
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [invalidMemo, setInvalidMemo] = useState(false);
  const [inputMemo, setInputMemo] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [possibleToCreateAddress, setPossibleToCreateAddress] = useState(false);
  const [createAddress, createAddressStatus] = useCreateAddressMutation();
  const [showDeleteAddress, setShowDeleteAddress] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const withdrawRequest = useWithdrawRequest();

  useEffect(() => {
    const assets = boostAssets.map(asset => assetsHook.getAsset(asset.toUpperCase())).map(asset => ({ asset, isBoost: true, isCustody: false}));
    custodyAssets.forEach(custodyAsset => {
      const alreadyInList = assets.find(a => a.asset?.identifier === custodyAsset)
      if(alreadyInList) {
        alreadyInList.isCustody = true
      } else {
        const asset = assetsHook.getAssetByIdentifier(custodyAsset)
        const newElement = { asset: asset!, isBoost: false, isCustody: true}
        assets.push(newElement)
      }
    })
    setAssets(assets)
  }, [boostAssets, custodyAssets, assetsHook.assets])

  useEffect(() => {
    if(asset?.asset?.identifier !== selectedAsset?.identifier) {
      const asset = assets.find(a => a.asset?.identifier === selectedAsset?.identifier)
      setAsset(asset)
    }
  }, [selectedAsset, assets, asset])

  useEffect(() => {
    if(availableAssets) {
      setCustodyAssets(availableAssets)
    }
  }, [availableAssets])

  useEffect(() => {
    const whitelistLoading = whitelistAddresses.isLoading || whitelistAddresses.isFetching;
    const ordersLoading = ordersSummary.isFetching || ordersSummary.isLoading;
    const deleteLoading = deleteAddressStatus.isLoading;
    const createLoading = createAddressStatus.isLoading;
    setIsLoading(whitelistLoading || ordersLoading || deleteLoading || createLoading || loadingNetworks);
  }, [whitelistAddresses, ordersSummary, deleteAddressStatus, createAddressStatus, loadingNetworks]);

  useEffect(() => {
    setAddresses(whitelistAddresses.data?.whitelist ?? []);
  }, [whitelistAddresses]);

  useEffect(() => {
    const assets = ordersSummary.data?.assets || [];
    const identifiers = assets.map((asset) => asset.asset || '');
    setBoostAssets(identifiers);
  }, [ordersSummary]);

  const validateAddress = () => {
    if (selectedNetwork && selectedNetwork.addressRegex) {
      let regex = selectedNetwork.addressRegex
      const indexOfStart = regex.indexOf("^")
      const indexOfEnd = regex.indexOf("$")
      if(indexOfStart !== -1 && indexOfEnd !== -1) {
        regex = regex.slice(indexOfStart, indexOfEnd+1)
      }
      const isMatch = new RegExp(regex).test(inputAddress)
      setInvalidAddress(!isMatch);
    }
  };

  const validateMemo = () => {
    if (selectedNetwork) {
      if(!selectedNetwork.requireMemo && !inputMemo){
        setInvalidMemo(false)
      } else if (inputMemo && selectedNetwork.memoRegex) {
        let regex = selectedNetwork.memoRegex
        const indexOfStart = regex.indexOf("^")
        const indexOfEnd = regex.indexOf("$")
        if(indexOfStart !== -1 && indexOfEnd !== -1) {
          regex = regex.slice(indexOfStart, indexOfEnd+1)
        }
        const isMatch = new RegExp(regex).test(inputMemo)
        setInvalidMemo(!isMatch);
      } else if(selectedNetwork.requireMemo && !inputMemo){
        setInvalidMemo(true)
      } else {
        setInvalidMemo(false)
      }
    }
  };

  useEffect(() => {
    setInputMemo('');
    setNotes('');
    setInputAddress('');
    setInvalidAddress(false);
    setInvalidMemo(false);
  }, [selectedNetwork, addresses]);

  useEffect(() => {
    if (selectedNetwork && inputAddress && selectedNetwork.addressRegex) {
      if (!invalidAddress) {
        if (selectedNetwork.requireMemo && inputMemo && selectedNetwork.memoRegex) {
          setPossibleToCreateAddress(!invalidMemo);
        } else {
          setPossibleToCreateAddress(true);
        }
      } else {
        setPossibleToCreateAddress(false);
      }
    } else {
      setPossibleToCreateAddress(false);
    }
  }, [inputAddress, inputMemo, selectedNetwork, selectedAsset, invalidAddress, invalidMemo]);

  const navigateBack = () => {
    if (withdrawId) {
      navigate(`${PagesUrl.WITHDRAW}/${withdrawId}`);
    } else {
      navigate(-1);
    }
  };
  const startDeleteAddress = (address: TransferAddress) => {
    setAddressToDelete(address.id ?? 0);
    setShowDeleteAddress(true);
  };

  const proceedDeleteAddress = async () => {
    await deleteAddress(addressToDelete);
    await whitelistAddresses.refetch();
    setShowDeleteAddress(false);
    toast.show({
      type: ToastType.Success,
      title: 'Success',
      content: 'Address deleted successfully',
    });
  };

  const markAsVerified = (address: TransferAddress) => {
    const updatedAddresses = addresses.map((a) => (a.id === address.id ? { ...a, verified: true } : a));
    setAddresses(updatedAddresses);
  };

  const createTransferAddress = async () => {
    if (selectedNetwork) {
      const address: TransferAddress = { address: inputAddress, network: selectedNetwork?.network, notes, asset: asset?.asset?.name, networkName: selectedNetwork.name };
      if (inputMemo) {
        address.memo = inputMemo;
      }
      await createAddress(address).unwrap();
      whitelistAddresses.refetch();
      toast.show({
        type: ToastType.Success,
        title: 'Success',
        content: 'Address created successfully',
      });
    }
  };
  const [filter, setFilter] = useState('');
  const assetsInAddresses = _.uniq(addresses.map((a) => a.asset ?? '-'));
  const filteredAddresses = filter ? addresses.filter((a) => a.asset === filter) : addresses;
  const verifiedAddresses = filteredAddresses.filter((a) => a.verified);
  const pendingConfirmFundAddresses = filteredAddresses.filter((a) => !a.verified && a.usedWithdrawCompleted);
  const pendingAddresses = filteredAddresses.filter((a) => !a.usedWithdrawCompleted && a.usedWithdrawRequest);
  const newAddresses = filteredAddresses.filter((a) => !a.usedWithdrawRequest);

  return (
    <Layout customGrid={alternativeGridSystem}>
      {isLoading && (
        <LoadingArea>
          <Loading showRoundSpinner size={SpinnerSizeEnum.LARGE} showText={false} />
        </LoadingArea>
      )}
      <Widget>
        <TitleBox>
          <Button buttonType={TypesNamesEnum.ACCENT} label='Add Address' height='55' onClick={() => setShowAddAddress(!showAddAddress)} width='120' />
          <FilterGoBack>
            <Select
              data={(filter ? ['', ...assetsInAddresses] : assetsInAddresses).map((asset) => ({
                id: asset,
                label: asset ? asset.toUpperCase() : 'All Assets',
              }))}
              label='Filter by Asset'
              onSelect={(item) => setFilter(item.id.toUpperCase())}
            ></Select>
            <Button buttonType={TypesNamesEnum.ACCENT} label='Go Back' height='55' onClick={navigateBack} width='90' />
          </FilterGoBack>
        </TitleBox>
        {showAddAddress && (
          <AddNewAddressBox>
            <h5>Add a New Transfer Address</h5>
            <Container>
              <AddressInformationBox>
                <CreateAddressBox>
                  <Select
                    val={assetName?.toUpperCase()}
                    data={assets.map((asset) => ({ id: asset.asset?.identifier ?? '', label: asset?.asset?.name ?? '' }))}
                    label='Select Asset'
                    onSelect={(item) =>  {
                      restart();
                      setSelectedAsset(item?.id)
                      setAsset(asset)
                    }}
                    selectedItem={{ id: asset?.asset?.identifier ?? '', label: asset?.asset?.name ?? '' }}
                  ></Select>
                  <Networks asset={asset} />
                </CreateAddressBox>
              </AddressInformationBox>
              <AddressAndMemoBox>
                {selectedNetwork && (
                  <>
                    <InputArea>
                      <label htmlFor='address'>Address: </label>
                      <TextInput
                        invalid={invalidAddress}
                        id='address'
                        placeholder='Wallet Address'
                        value={inputAddress}
                        onChange={(e) => setInputAddress(e.target.value)}
                        onBlur={validateAddress}
                      />
                    </InputArea>
                    {selectedNetwork.memoRegex && (
                      <InputArea>
                        <label htmlFor='memo'>Memo:</label>
                        <TextInput
                          invalid={invalidMemo}
                          id='memo'
                          placeholder='Wallet Destination Tag/Memo'
                          value={inputMemo}
                          onChange={(e) => setInputMemo(e.target.value)}
                          onBlur={validateMemo}
                        />
                      </InputArea>
                    )}
                    <InputArea>
                      <label htmlFor='notes'>Note:</label>
                      <TextInput id='notes' placeholder='Feel free to add a note to this address' value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </InputArea>
                    <Button
                      isLoading={isLoading}
                      height='50'
                      buttonType={TypesNamesEnum.ACCENT}
                      label='Add Address'
                      disabled={!possibleToCreateAddress}
                      onClick={createTransferAddress}
                    />
                  </>
                )}
              </AddressAndMemoBox>
            </Container>
          </AddNewAddressBox>
        )}
        <GroupboxContainer>
          {verifiedAddresses.length > 0 && (
            <GroupboxAddress>
              <h5>Verified Address{verifiedAddresses.length > 1 ? 'es' : ''}</h5>
              <AddressesBoxContainer>
                {verifiedAddresses.map((address) => (
                  <AddressBox
                    requests={withdrawRequest.withdrawRequests}
                    expanded={withdrawRequest.addressId == address.id}
                    expandAction={withdrawRequest.setAddressId}
                    loading={withdrawRequest.loading}
                    markAsVerified={markAsVerified}
                    key={address.id}
                    address={address}
                    action={() => startDeleteAddress(address)}
                  />
                ))}
              </AddressesBoxContainer>
            </GroupboxAddress>
          )}
          {pendingConfirmFundAddresses.length > 0 && (
            <GroupboxAddress>
              <h5>Awaiting Verification</h5>
              <InfoParagraph>Please confirm that you have received the funds</InfoParagraph>
              <AddressesBoxContainer>
                {pendingConfirmFundAddresses.map((address) => (
                  <AddressBox
                    requests={withdrawRequest.withdrawRequests}
                    expanded={withdrawRequest.addressId == address.id}
                    expandAction={withdrawRequest.setAddressId}
                    loading={withdrawRequest.loading}
                    markAsVerified={markAsVerified}
                    key={address.id}
                    address={address}
                    action={() => startDeleteAddress(address)}
                  />
                ))}
              </AddressesBoxContainer>
            </GroupboxAddress>
          )}
          {pendingAddresses.length > 0 && (
            <GroupboxAddress>
              <h5>Verification in Progress</h5>
              <InfoParagraph>Once the test transaction has been submited to the blockchain, you will be notified</InfoParagraph>
              <AddressesBoxContainer>
                {pendingAddresses.map((address) => (
                  <AddressBox
                    requests={withdrawRequest.withdrawRequests}
                    expanded={withdrawRequest.addressId == address.id}
                    expandAction={withdrawRequest.setAddressId}
                    loading={withdrawRequest.loading}
                    markAsVerified={markAsVerified}
                    key={address.id}
                    address={address}
                    action={() => startDeleteAddress(address)}
                  />
                ))}
              </AddressesBoxContainer>
            </GroupboxAddress>
          )}
          {newAddresses.length > 0 && (
            <GroupboxAddress>
              <h5>Unverified Address{newAddresses.length > 1 ? 'es' : ''}</h5>
              <AddressesBoxContainer>
                {newAddresses.map((address) => (
                  <AddressBox
                    requests={withdrawRequest.withdrawRequests}
                    expanded={withdrawRequest.addressId === address.id}
                    expandAction={withdrawRequest.setAddressId}
                    loading={withdrawRequest.loading}
                    markAsVerified={markAsVerified}
                    key={address.id}
                    address={address}
                    action={() => startDeleteAddress(address)}
                  />
                ))}
              </AddressesBoxContainer>
            </GroupboxAddress>
          )}
        </GroupboxContainer>
        <br></br>
      </Widget>
      <Modal visible={showDeleteAddress}>
        <Modal.Body>
          <h3>Are you sure you want to delete the transfer address?</h3>
          <br></br>
          <p>If needed you can always add it back in the future</p>
        </Modal.Body>
        <Modal.Footer>
          <Button buttonType={TypesNamesEnum.SECONDAY} label='Cancel' height='40' onClick={() => setShowDeleteAddress(false)} disabled={isLoading} />
          <Button buttonType={TypesNamesEnum.ACCENT} label='Confirm' height='40' onClick={proceedDeleteAddress} isLoading={isLoading} disabled={isLoading} />
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

const FilterGoBack = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
`;
const GroupboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-top: 3vh;
`;
const TitleBox = styled.div`
  display: flex;
  align: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;
const LoadingArea = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const InfoParagraph = styled.p`
  color: #ffffffcc;
  font-size: 0.7rem;
`;
const GroupboxAddress = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 8px;
`;
const AddressesBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
  margin-top: 2vh;
`;

const InputArea = styled.div`
  display: flex;
  width: 100%;
  gap: 1vw;
  align-items: center;
  label {
    font-size: 0.7rem;
    width: 60px;
  }
`;
interface TextInputProps {
  invalid?: boolean;
}
const TextInput = styled.textarea<TextInputProps>`
  background-color: transparent;
  color: white;
  border: 0;
  resize: vertical;
  border-bottom:${({ invalid }) => (!invalid ? '1px solid rgba(255,255,255,0.8)' : '2px solid rgba(255,20,20,0.8)')};
  width: 100%;
  min-height: 30px;
  min-width: 90px;
  max-width: 100%;
`;

const Container = styled.div`
  display: flex;
  margin-top: 3vh;
  flex-wrap: wrap;
  padding: 0;
  gap: 3vw;
`;
const AddressAndMemoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  gap: 1vh;
  flex-grow: 1;
`;

const AddressInformationBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 300px;
`;
const CreateAddressBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  flex-grow: 1;
`;

const AddNewAddressBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1vh 1vh;
  border-radius: 8px;
`;
export default WhitelistedAddresses;
