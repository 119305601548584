import React, { FC, useState } from "react";
import { getText } from "shared/locale-helper";
import { palette } from "lib/theme";
import { Text } from "components/atoms/Typography";
import AnimatedWidgetStep from "components/atoms/Animated/AnimatedWidgetStep";
import Icon from "components/atoms/Icon";
import LtbInformationBody from "components/molecules/LtbInformationBody/LtbInformationBody";
import Sidebar from "components/molecules/Sidebar";
import styled from "styled-components";
import Tooltip from "components/atoms/Tooltip";
import { OrderDto } from "state/store/api";
import { getInfoNotification } from "./alerts/NotificationLTV";

type ChartMeasures = {
  warning: number;
  collateralCall: number;
  liquidation: number;
  initialLtv?: number;
};
interface ILoanToValueRatio {
  order?: OrderDto;
  chartMeasures?: ChartMeasures;
  currentLtv?: number;
}

const LoanToValueRatio: FC<ILoanToValueRatio> = ({
  order,
  chartMeasures = {
    warning: 0.65,
    collateralCall: 0.65,
    liquidation: 0.65,
  },
  currentLtv = 8,
}) => {
  const notificationType = getInfoNotification(order)?.type;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  let warning = chartMeasures?.warning || 0;
  let collateralCall = chartMeasures?.collateralCall || 0;
  let liquidation = chartMeasures?.liquidation || 0;
  let current = currentLtv || 0;

  if (warning === 0 && collateralCall === 0 && liquidation === 0) {
    warning = 0.65;
    collateralCall = 0.75;
    liquidation = 0.9;
  }

  if (collateralCall - warning < 0.1) warning = warning - 0.1;
  if (liquidation - collateralCall) collateralCall = collateralCall - 0.1;
  if (liquidation === collateralCall) liquidation = liquidation + 0.1;

  const getCurrentLtvColor = () => {
    if (notificationType === "warning") return palette.orange.main;
    if (notificationType === "danger") return palette.red.main;

    return palette.green.main;
  };

  const getCurrentLtvPosition = () => {
    if (notificationType === "warning")
      return (collateralCall - warning) / 2 + warning;
    if (notificationType === "danger")
      return collateralCall + (liquidation - collateralCall) / 2;

    return warning / 2;
  };

  const getTooltipText = () => {
    return (
      <div style={{ textAlign: "left" }}>
        <Text size="small" letterSpacing="-0.5">
          {getText("ltv_refer")}
          <br />
          <br />
          {getText("ltv_example")}
          <Text
            size="small"
            letterSpacing="-0.5"
            onClick={() => setIsSidebarOpen(true)}
            color={palette.purple.light}
          >
            {getText("learn_more")}
          </Text>
        </Text>
      </div>
    );
  };

  return (
    <>
      <TitleRow>
        <Text
          size="tiny"
          bold
          letterSpacing="0.5px"
          color={palette.darkBackgroundContrast.light70}
        >
          {String("LTV (Loan-to-value)").toUpperCase()}
        </Text>
        <Tooltip
          displayAsText
          autoDismiss={false}
          maxWidth={450}
          placement="bottom"
          text={getTooltipText()}
        >
          <Icon name="QuestionCircleBig" size="12" />
        </Tooltip>
      </TitleRow>
      <ChartBox>
        <StyledLegendContainer offsetX={getCurrentLtvPosition()} offsetY={-41}>
          <span style={{ color: getCurrentLtvColor() }}>
            {getText("current")} ({`${current * 100}%`})
          </span>
          <StyledPointer>
            <Icon
              name="LTVIndicatorDown"
              color={getCurrentLtvColor()}
              size={12}
            />
          </StyledPointer>
        </StyledLegendContainer>
        <StyledLTVContainer>
          <StyledLTVPartialFill
            background={palette.green.main as string}
            width={warning}
            initial
          />
          <StyledLTVPartialFill
            background={palette.orange.main as string}
            width={collateralCall - warning}
          />
          <StyledLTVPartialFill
            background={palette.red.main as string}
            width={liquidation - collateralCall}
          />
        </StyledLTVContainer>
        <StyledLegendContainer offsetX={warning} offsetY={13}>
          <StyledPointer>
            <Icon name="LTVIndicatorUp" size={12} />
          </StyledPointer>
          <Text color={palette.secondary.secondaryFont} size="tiny">
            {getText("ltv_orange_desc")}
          </Text>
        </StyledLegendContainer>

        <StyledLegendContainer offsetX={collateralCall} offsetY={13}>
          <StyledPointer>
            <Icon name="LTVIndicatorUp" size={12} />
          </StyledPointer>
          <Text color={palette.secondary.secondaryFont} size="tiny">
            Margin call
          </Text>
        </StyledLegendContainer>

        <StyledLegendContainer offsetX={liquidation} offsetY={13}>
          <StyledPointer>
            <Icon name="LTVIndicatorUp" size={12} />
          </StyledPointer>
          <Text color={palette.secondary.secondaryFont} size="tiny">
            Liquidation
          </Text>
        </StyledLegendContainer>
      </ChartBox>
      <AnimatedWidgetStep name="transactionHistory">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            margin: "0 -24px",
          }}
        >
          <Sidebar
            title={getText("loan_health")}
            show={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            bodyMarginTop="18px"
            titleMargin="0px 0px 0px -25px"
          >
            <LtbInformationBody onClose={setIsSidebarOpen} />
          </Sidebar>
        </div>
      </AnimatedWidgetStep>
    </>
  );
};

const StyledLTVContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background-color: transparent;
  border-radius: 60px 60px;
  box-sizing: border-box;
  background: repeating-linear-gradient(
    116deg,
    ${palette.red.main},
    ${palette.red.main} 8px,
    transparent 8px,
    transparent 16px
  );
  border-radius: 60px;
`;

const StyledLTVPartialFill = styled.div<{
  background: string;
  width: number;
  initial?: boolean;
}>`
  height: 8px;
  width: ${({ width }) => width * 100}%;
  background-color: ${({ background }) => background};
  border-radius: ${({ initial }) => (initial ? "60px 0 0 60px" : "none")};
`;

const StyledLegendContainer = styled.div<{ offsetX: number; offsetY: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 110px;
  text-align: center;
  align-items: center;
  left: calc(${({ offsetX }) => offsetX * 100}% - 51px);
  top: ${({ offsetY }) => offsetY}px;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  font-feature-settings: "ss02" on, "liga" off;
  z-index: 200;
`;

const StyledPointer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const ChartBox = styled.div`
  position: relative;
  margin: 50px 0px;
`;
export default LoanToValueRatio;
