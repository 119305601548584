import * as earn from "./earn.json";
import * as borrow from "./borrow.json";
import * as perx from "./perx.json";
import * as trade from "./trade.json";

export const iconsWithEffectJson = {
  'Earn': earn,
  'Borrow': borrow,
  'Perx': perx,
  'Overview': trade,
  
};
