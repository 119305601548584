import { palette } from "lib/theme";
import styled from "styled-components";

export const Title = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  font-feature-settings: "ss02" on, "liga" off;
  color: ${palette.secondary.secondaryFont};
`;
