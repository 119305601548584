import CheckMark from 'assets/icons/CheckMark';
import palette from 'lib/theme/palette';
import React, { FC, useState } from 'react';
import { CheckboxContainer, StyledCheckbox, Icon } from './../../../styles/components/checkbox';
import { Text } from './../Typography/Text';

import { CheckboxProps } from './Checkbox.interface';

const Checkbox: FC<CheckboxProps> = ({
  checked,
  error,
  disabled,
  avoidTextClick = false,
  name,
  onChange,
  text,
  textFormated,
  value,
  marginTopBox,
  className,
  dontUseDefaultMargin,
  dataCy,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnChange = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && !avoidTextClick) {
      setIsChecked(!isChecked);
      if (onChange) onChange(!isChecked);
    }

    event.preventDefault();
  };

  const handleOnClickBox = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && avoidTextClick) {
      setIsChecked(!isChecked);
      if (onChange) onChange(!isChecked);
    }

    event.preventDefault();
  };

  return (
    <>
      <CheckboxContainer
        onClick={handleOnChange}
        className={className}
        dontUseDefaultMargin={dontUseDefaultMargin}
        disabled={disabled}
        style={{ color: 'white' }}
      >
        <input style={{ display: 'none' }} type='checkbox' value={value} name={name} checked={isChecked} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ marginTop: marginTopBox ? `${marginTopBox}px` : '' }} onClick={handleOnClickBox}>
            <StyledCheckbox data-cy={dataCy} checked={isChecked} disabled={disabled} error={error} dontUseDefaultMargin={dontUseDefaultMargin}>
              <span style={{ position: 'relative', left: '20%', top: '-10%' }}>
                <CheckMark size={isChecked ? 11 : 0} active={!disabled} />
              </span>
            </StyledCheckbox>
          </div>
          <Text size='small' color={palette.darkBackgroundContrast.light70}>
            {textFormated ? textFormated : text}
          </Text>
        </div>
      </CheckboxContainer>
    </>
  );
};

export default Checkbox;
