import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import useStrategy from './useStrategy';
import styled from 'styled-components';
import useInvest from './useInvest';
import useAssets from 'shared/useAssets';
import Modal from 'components/organisms/Modal';
import { CustodyStyledTable } from '../CustodyStyles';
import { SpecificDefiBalance, useConfirmDefiTransactionRequestMutation, useCreateDefiTransactionRequestMutation } from 'state/store/investApi';
import bigDecimal from 'js-big-decimal';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import { ToastType, toast } from 'components/organisms/Toast';
import { Percentage } from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import Tooltip from 'components/atoms/Tooltip';
import { Info } from 'assets/icons'

const InvestStrategySelected = () => {
  const { strategyIdentifier } = useParams();
  const { defiBalances } = useInvest();
  const [minError, setMinError] = useState(false);
  const { transactionsInfo } = useStrategy(strategyIdentifier ?? '');
  const { getAssetByIdentifier, getPriceFormattedI, assetsLoading, getDollarPrice } = useAssets();
  const getCurrentInvestment = () => {
    const data = defiBalances.data ?? [];
    const balancesFromThisStrategy = data.filter((d) => d.identifier === strategyIdentifier);
    const balancesWithAmount = balancesFromThisStrategy.filter((d) => +(d.balance?.amount ?? '0'));
    const balances = balancesWithAmount.filter((d) => +(d.yield?.apr ?? '0'));
    const currentInvestment = balances?.length ? balances[0] : undefined;
    return currentInvestment;
  };

  
  const currentInvestment = getCurrentInvestment();

  const { strategyDetails, strategyBalance } = useStrategy(strategyIdentifier ?? '');

  const getCurrentInvestedValues = () => {
    const data = strategyBalance.data ?? [];
    const pendingData = data.filter((d) => !d.pending);
    const totalInvestedValues = pendingData.reduce(sumDefi, new bigDecimal(0));
    return totalInvestedValues;
  };

  const sumDefi = (total: bigDecimal, balance: SpecificDefiBalance) => {
    return total.add(new bigDecimal(balance.balance?.amount ?? '0'));
  };

  const currentInvestedValues = getCurrentInvestedValues();

  const [investmentAmount, setInvestmentAmount] = useState('0');

  const changeInvestmentAmount = (value: string) => {
    const num = value.replace(/[^\.0-9]/g, '');
    setInvestmentAmount(num);
  }
  const [showModal, setShowModal] = useState(false);

  const asset = getAssetByIdentifier(transactionsInfo.data?.availableAmountInAsset?.asset);

  const dollarPrice = getDollarPrice(asset?.identifier, investmentAmount);
  const dollarPriceFee = transactionsInfo.data?.totalEstimatedFeeInConvenience.amount;
  const [createDefiTransaction, createMeta] = useCreateDefiTransactionRequestMutation();
  const [confirmDefiTransaction, confirmMeta] = useConfirmDefiTransactionRequestMutation();

  const submit = async () => {
    try {
      const transaction = await createDefiTransaction({
        amount: +investmentAmount,
        currency: asset?.identifier ?? '',
        transactionType: 'DEPOSIT',
        strategyIdentifier: strategyIdentifier ?? '',
      }).unwrap();
      await confirmDefiTransaction(transaction.publicUID).unwrap();
      toast.show({
        type: ToastType.Success,
        title: 'Your request was successful',
        content: '',
      });
      navigate('/custody');
    } catch (e: any) {

      if(e?.status === 500) {
        toast.show({
          type: ToastType.Fail,
          title: e.data?.description,
          content: e.data?.message,
          duration: 20000,
        });
      } else {
        toast.show({
          title: "An error has occurred: " + e?.status,
          content: "Please try again later or contact your account manager.",
          type: ToastType.Fail,
          duration: 20000,
        });
      }

    }
  };
  let canvas: HTMLCanvasElement;
  const getTextWidth = (text: string, font: any) => {
    canvas = canvas ? canvas : document.createElement('canvas');
    var context = canvas.getContext('2d');
    context!.font = font;
    var metrics = context!.measureText(text);
    return Math.max(metrics.width, 10);
  };

  const navigate = useNavigate();

  useCustodyRedirect();

  const setMax = () => {
    const amount = transactionsInfo.data?.availableAmountInAsset?.amount ?? '0'
    if(+amount > 0) {
      setInvestmentAmount(amount);
    }
  }
  const renderMaxButton = +(transactionsInfo.data?.availableAmountInAsset?.amount ?? '0') > 0
  const accountBalanceInDollars = getDollarPrice(asset?.identifier, transactionsInfo.data?.totalAmountInAsset?.amount)
  const availableBalanceInDollars = getDollarPrice(asset?.identifier, transactionsInfo.data?.availableAmountInAsset?.amount)

  const [spanWidth, setSpanWidth] = useState(0)
  const valueWidth = getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"');
  const spanDollarPrice = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!spanDollarPrice.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if(spanDollarPrice.current?.offsetWidth !== spanWidth) {
        setSpanWidth(spanDollarPrice.current?.offsetWidth ?? 0); 
      }
    });
    resizeObserver.observe(spanDollarPrice.current);
    
    return () => {
      resizeObserver.disconnect();
    }
  }, [spanDollarPrice.current])


  useEffect(() => {
    const data = transactionsInfo.data?.minimumTransactionAmounts.find(amounts => amounts.asset === asset?.identifier)
    const minAmount = data?.amount ?? '0'
    setMinError(+investmentAmount < +minAmount)
  }, [investmentAmount])

  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          {transactionsInfo.isLoading || defiBalances.isLoading || strategyDetails.isLoading || assetsLoading ? (
            <>
              <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
            </>
          ) : (
            <>
              <Header>
                <PageTitle>
                  Invest in {strategyDetails.data?.name}{' '}
                  {currentInvestment ? <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</> : <></>}
                </PageTitle>
              </Header>
              <Content>
                <FormInformation>
                  <ForMoreInformation>
                    For more information about this investment strategy, please
                    <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}> click here</CustodyClickableText>
                  </ForMoreInformation>
                  {transactionsInfo.data?.nextInvestmentCutoff && (
                    <>
                      <p>You can cancel your request till <StrongText>{moment.utc(transactionsInfo.data?.nextInvestmentCutoff).format('MMM Do')}</StrongText></p>
                      <p>You will start earning yield effective <StrongText>{moment.utc(transactionsInfo.data?.nextEffectiveDate).format('MMM Do')}</StrongText></p>
                    </>
                  )}
                  {!!transactionsInfo.data?.estimatedMaxTransactionFeeBasisPoints && <EstimatedFeeBpsBox>
                    Estimated transaction fee of <StrongText>{transactionsInfo.data?.estimatedMinTransactionFeeBasisPoints}</StrongText> bps 
                    - <StrongText>{transactionsInfo.data?.estimatedMaxTransactionFeeBasisPoints}</StrongText> bps 
                    <Tooltip text={`This is estimated based on what users paid on average in the last ${transactionsInfo.data?.estimatedTransactionFeeIntervals} months. Network fee to send the funds into the strategy will be additional`}><span> <Info color='#fffc' size={12} /></span></Tooltip>
                  </EstimatedFeeBpsBox>}
                  <Space />
                  <p>
                    Current account balance is <StrongText>{getPriceFormattedI(asset?.identifier, transactionsInfo.data?.totalAmountInAsset?.amount)}</StrongText> {accountBalanceInDollars !== '-' && <>({accountBalanceInDollars})</>}
                  </p>
                  <p>
                    Current invested amount is {strategyBalance.data?.filter(d => !d.pending).map(d => <StrongText>{getPriceFormattedI(d.quantity?.asset, d.quantity?.amount)} </StrongText>)}<span>({getUsDollar(currentInvestedValues.getValue())})</span>
                  </p>
                  <p>
                    Minimum investment amount is {transactionsInfo.data?.minimumTransactionAmounts.map((min) => <StrongText key={min.asset}>{getPriceFormattedI(min.asset, min.amount)} </StrongText>)}
                  </p>
                  <p>
                    Amount of {asset?.name} available for additional investment is <StrongText>{getPriceFormattedI(asset?.identifier, transactionsInfo.data?.availableAmountInAsset?.amount)}</StrongText> {availableBalanceInDollars !== '-' && <>({availableBalanceInDollars})</>}
                    <Tooltip text='Your balance available for investment may be lower than the actual account balance because of estimated network fee as well as management fee and gas fee reserve requirements'>
                      <strong> <Info color='#fffc' size={12} /></strong>
                    </Tooltip>
                  </p>
                  <FormInputBox>
                    <p>{!!parseFloat(currentInvestedValues.getValue()) && <>Additional</>} Investment Amount</p>
                    <InputArea size={getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"')}>
                      <InputAmount size={spanWidth + valueWidth} type='number' value={investmentAmount} onChange={(e) => changeInvestmentAmount(e.target.value)}></InputAmount>
                      <span ref={spanDollarPrice}>{asset?.name} { dollarPrice !== '-' && <>({dollarPrice})</> }</span>
                    </InputArea>
                    {renderMaxButton &&
                      <MaxButton onClick={() => setMax()} >MAX</MaxButton>
                    }
                  </FormInputBox>
                </FormInformation>
                <RightAlignedBox>
                  <ReviewButton
                    onClick={() => setShowModal(!showModal)}
                    disabled={minError || +(transactionsInfo.data?.availableAmountInAsset.amount ?? '0') < +investmentAmount}
                  >
                    Review
                  </ReviewButton>
                </RightAlignedBox>
                {minError && <p style={{color: 'red'}}>This amount is lower than the transaction minimum allowed</p>}
              </Content>
            </>
          )}
        </CustodyNavigationWidget>
        <Modal visible={showModal} onClose={() => setShowModal(false)} header='Confirmation Page'>
          <Modal.Body>
            <ModalContent>
              <p>
                Invest{' '}
                <strong>
                  {investmentAmount} {asset?.name}
                </strong>{' '}
                {dollarPrice !== '-' && <StrongText>({dollarPrice})</StrongText>} in
                <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}>
                  {' '}
                  {strategyDetails.data?.name}
                </CustodyClickableText>{' '}
                {currentInvestment && <>, which offers a current APR of <StrongText>{new Percentage(currentInvestment.yield.apr).print()}</StrongText></>}
              </p>
              {transactionsInfo.data?.nextEffectiveDate && <>
                <p>This strategy will start generating yield from { moment.utc(transactionsInfo.data?.nextEffectiveDate).format('MMM Do')}</p>
              </>}
              <p>
                Estimated network fee is{' '}
                <StrongText>
                  {transactionsInfo.data?.totalEstimatedFeeAmounts.map((fee) => getPriceFormattedI(fee.asset, fee.amount)).join(', ')} (${dollarPriceFee})
                </StrongText>
              </p>
              {!!transactionsInfo.data?.estimatedMaxTransactionFeeBasisPoints && <>
                <p>
                  Estimated transaction fee will be {transactionsInfo.data?.estimatedMinTransactionFeeBasisPoints} bps to {transactionsInfo.data?.estimatedMaxTransactionFeeBasisPoints} bps
                </p>
              </>}
              <p>Annual Abra Management Fee - {strategyDetails.data?.annualFeeBasisPoints} bps</p>
            </ModalContent>
            { !!transactionsInfo.data?.stepDetails?.length && 

            <AutomationStepsBox>
              <h4>Automation Steps</h4>
              <CustodyStyledTable>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Expected Fees</th>
                    <th>Fees in USD</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionsInfo.data?.stepDetails?.map((step) => (
                    <tr>
                      <td>{step.description}</td>
                      <td>{step.estimatedFeeAmount ? getPriceFormattedI(step.estimatedFeeAmount?.asset, step.estimatedFeeAmount?.amount) : '-'}</td>
                      <td>
                        {step.estimatedConvenienceFeeAmount
                          ? getPriceFormattedI(step.estimatedConvenienceFeeAmount?.asset, step.estimatedConvenienceFeeAmount?.amount)
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustodyStyledTable>
            </AutomationStepsBox>
            }
          </Modal.Body>
          <Modal.Footer>
            <RightAlignedBox>
              <ReviewButton onClick={submit} disabled={createMeta.isLoading || confirmMeta.isLoading || minError}>
                Submit
              </ReviewButton>
            </RightAlignedBox>
          </Modal.Footer>
        </Modal>
      </MarginContainer>
    </Layout>
  );
};

const Space = styled.div`
  margin-bottom: 6px;
`
const StrongText = styled.strong`
  font-weight: 500;
`
const MaxButton = styled.button`
  background-color: #6f2acd;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 6px 18px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`;

const FormInputBox = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
`;
const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const AutomationStepsBox = styled.div``;
const ModalContent = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-size: 1rem;
`;
const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #a58bf2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const ForMoreInformation = styled.p`
  margin-bottom: 8px;
`;

const EstimatedFeeBpsBox = styled.div`
  margin-bottom: 8px;
`
interface InputAreaProps {
  size: number;
}
const NextEffectiveDateBox = styled.div`
`
const InputArea = styled.div<InputAreaProps>`
  position: relative;
  span {
    top: 0px;
    user-select: none;
    font-size: 13.3333px;
    left: ${(props) => props.size + 13}px;
    position: absolute;
  }
`;
interface ReviewButtonProps {
  disabled: boolean;
}

const ReviewButton = styled.button<ReviewButtonProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  background-color: ${({ disabled }) => (disabled ? '#555' : '#6F2ACDAA')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.7rem;
  opacity: 0.9;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 1.1rem;
  padding: 6px 18px;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#555' : 'rgba(163, 153, 246, 0.8)')};
  }
`;

const USDEquivalent = styled.span`
  font-size: 0.8rem;
  grid-column: 2/3;
`;
const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;
interface InputAmountProps {
  size: number;
}

const InputAmount = styled.input<InputAmountProps>`
  background-color: transparent;
  color: white;
  min-width: 32ch;
  border: 0px;
  width: ${(props) => props.size+20}px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  padding: 0px 8px 0px 8px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
`;

const PageTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default InvestStrategySelected;
