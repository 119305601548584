import { Copy } from 'assets/icons'
import Image from 'components/atoms/Image'
import { toast, ToastType } from 'components/organisms/Toast'
import React from 'react'
import { AssetDto } from 'state/store/api'
import { CustodyBalance, CustodyNetworkMap } from 'state/store/custodyApi'
import styled from 'styled-components'
import { CustodyMainText, CustodyStyledPrintableTable, CustodyStyledTable } from '../CustodyStyles'
import useCustodyStatement from './useCustodyStatement'
import { useParams } from 'react-router-dom'
import { StatementWithdraw } from 'state/store/statementsApi'
import useAssets from 'shared/useAssets'

const CustodyStatementCostBasisWithdrawals = () => {
  const { month, year } = useParams()
  const {realizedWithdrawals} = useCustodyStatement({month, year})

  return (
  <div>
    <TokenSummaryTitle>Cost Basis for Withdrawals</TokenSummaryTitle>
    <CustodyStyledPrintableTable>
      <thead>
        <tr>
          <th>Date</th>
          <th>Network</th>
          <th>Amount</th>
          <th>USD Value</th>
          <th>Total Network Fee</th>
          <th>Overall Cost Basis</th>
          <th>Transaction Id</th>
          <th>Acquisition Date</th>
        </tr>
      </thead>
      <tbody>
        {realizedWithdrawals?.data?.map((record, index) => <CustodySummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledPrintableTable>
  </div>
  )
}

interface CustodySummaryRowProps {
  record: StatementWithdraw
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({record}) => {

  const {getPriceFormattedI} = useAssets()
  return (
    <tr>
      <td>
        <MainRowText>
        {record.closingDate}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {record.network}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getPriceFormattedI(record.amount.asset, record.amount.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getPriceFormattedI(record.totalFees.asset, record.totalFees.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {getPriceFormattedI(record.realizedAmount.asset, record.realizedAmount.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
        {record.abraTxUID}
        </MainRowText>
      </td>
      <td>
        <MockText>
        -
        </MockText>
      </td>
    </tr>
  )
}

interface GainLossProps {
  loss: boolean
}
const GainLossArea = styled.span<GainLossProps>`
  ${p => p.loss ? 'color: #EC396B;' : ''}
`

const MockText = styled.span`
  color: #E99928;
`

const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`
const ImageBox = styled.div`
  max-width: calc(min(4vw, 60px));
  min-width: calc(min(4vw, 60px));
`
const CopyBox = styled.div`
  width: 20px;
  height: 20px;
`
const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 10vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  overflow: hidden;
`
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const MainRowText = styled.span`
`

const SubRowText = styled.span`
  font-weight: 400;
  color: #FFFFFF;
  font-size: 80%;
  opacity: 0.7;
  cursor: inherit;
`

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`
export default CustodyStatementCostBasisWithdrawals
