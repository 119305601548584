import React, { FC, useState } from 'react';
import Icon from '../Icon';
import { New } from 'assets/icons';
import { NavigationItemStyled } from './NavigationItem.styles';
import { NavigationItemProps } from './NavigationItem.interface';
import { Text } from '../Typography';
import Label from 'components/atoms/label/Label';
import { palette } from 'lib/theme';
import IconWithEffect from '../Icon/IconWithEffect';
import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';
import { useLocation } from 'react-router-dom'

const NavigationItem: FC<NavigationItemProps> = (props) => {
  const location = useLocation()
  const active = props.link && location.pathname.includes(props.link)
  const [onHover, setOnHover] = useState(false);

  const onMouseEnterIconColor = () => {
    setOnHover(true);
  };

  const onMouseLeaveIconColor = () => {
    setOnHover(false);
  };

  return (
    <NavigationItemStyled
      data-cy={`navigation_item_${props.text.toLocaleLowerCase()}`}
      onMouseEnter={() => onMouseEnterIconColor()}
      onMouseLeave={() => onMouseLeaveIconColor()}
      {...props}
    >
      <DivWithTransition active={active}>
        <div>
          {onHover && (props.icon !== 'QuestionCircle' && props.icon !== 'Email') ? (
            <IconWithEffect onHover={onHover} iconName={props.icon} />
          ) : (
            <Icon size={24} name={props.icon} color={palette.darkBackgroundContrast.white} />
          )}
        </div>
        <Text size={props.bigger ? 'large' : 'small'} align='center' letterSpacing='-0.5' color={palette.darkBackgroundContrast.white}>
          <EyeContainer active={active}>
            {props.text}
            {props.isHidden ? (
              <Tooltip text='Users from this organization are not able to see this option'>
                <Icon name='Eye' size={10} color={palette.darkBackgroundContrast.white} />
              </Tooltip>
            ) : (
              <></>
            )}
          </EyeContainer>
        </Text>
        {props.isNew && (
          <Label left='50%' top='50%' shift='0, -50%'>
            <New />
          </Label>
        )}
      </DivWithTransition>
    </NavigationItemStyled>
  );
};

const DivWithTransition = styled.div<{ active?: boolean | "" | undefined }>`
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 2.5em;
  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background: ${palette.purple.light80};
      }
    `}
  ${(props) =>
    props.active &&
    css`
      background: ${palette.purple.light80};
    `}
`;

const EyeContainer = styled.div<{ active?: boolean | "" | undefined }>`
  display: flex;
  justify-content: center;
  gap: 0.5vw;
  font-weight: ${(props) => props.active ? 600 : 0};
`;

export default NavigationItem;
