import { PaginationTypeEnum } from '@types/enums/Pagination.enum'
import Pagination from 'components/organisms/AbraTable/table-components/Pagination'
import { toast, ToastType } from 'components/organisms/Toast'
import bigDecimal from 'js-big-decimal'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CustodyTransaction } from 'state/store/custodyApi'
import styled from 'styled-components'
import useStrategy from './useStrategy'
import useAssets from 'shared/useAssets'
import { CustodyMainText, CustodyStyledTable, CustodySubText } from '../CustodyStyles'
import { formatDate } from 'shared'
import getUsDollar from 'shared/dollarFormat'
import TokenQuantity from 'components/atoms/TokenQuantity/TokenQuantity'


const StrategyDetailsTransactionDetails = () => {

  const { strategyIdentifier } = useParams();
  const { defiTransactions, setTransactionsPage } = useStrategy(strategyIdentifier ?? '');

  const changePage = (page: number) => {
    setTransactionsPage(page)
  }

  return (
  <div>
    {!defiTransactions.isLoading && defiTransactions.data?.totalElements! > 0 &&
    <>
    <TokenSummaryTitle>Transaction Details</TokenSummaryTitle>
    <CustodyStyledTable blur={defiTransactions.isFetching}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Network</th>
          <th>Asset</th>
          <th>Action</th>
          <th>Type</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Network Fee</th>
          <th>Blockchain Id</th>
          <th>Transaction Id</th>
        </tr>
      </thead>
      <tbody>
        {defiTransactions.data?.content.map((record, index) => <TokenSummaryRow key={index} record={record} />)}
      </tbody>
    </CustodyStyledTable>
    
    {defiTransactions.data ? <>
      <Pagination 
          totalRecords={defiTransactions.data?.totalElements} 
          activePageNumber={defiTransactions.data?.number} 
          perPage={defiTransactions.data.size} 
          handlePagination={(page: number)=>{changePage(page)}} 
          variant={PaginationTypeEnum.PAGES}/>
    </> : <></>}
    </>
    
  }
  </div>
  )
}

interface TokenSummaryRow {
  record: CustodyTransaction
}
const TokenSummaryRow: React.FC<TokenSummaryRow> = ({record}) => {
  const { getAssetByIdentifier, getPriceI } = useAssets()
  const asset = getAssetByIdentifier(record.amount?.asset)
  const feeAsset = getAssetByIdentifier(record?.networkFee?.asset)
  
  const generateFeeValue = (record: CustodyTransaction) => {
    const rate = new bigDecimal(record?.networkFeeConvenienceRate?.rate ?? '0')
    const amount = new bigDecimal(record?.networkFee?.amount ?? '0')
    const value = rate.multiply(amount)
    return value.abs().round(2).getValue()
  }

  const generateRecordValue = (record: CustodyTransaction) => {
    const rate = new bigDecimal(record.amountConvenienceRate?.rate ?? '0')
    const amount = new bigDecimal(record.amount.amount ?? '0')
    const value = rate.multiply(amount)
    return value.round(2).getValue()
  }

  const goTo = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  }

  const copyAddress = (address: string) => {
    try {
      navigator.clipboard.writeText(address)
      toast.show({
        title: 'Value copied',
        content: 'The value was copied to the clipboard',
        duration: 5000,
        type: ToastType.Info
      })
    } catch(e) {

    }
  }

  return (
    <tr>
      <td>
        <CustodyMainText>
          {formatDate(record.dateTime)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.networkName}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{asset?.name}</CustodyMainText>
        </TextGroup>
      </td>
      <td>
        <CustodyMainText>
          {record.actionName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.transactionCategory}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.amountConvenienceRate?.rate ? <>
          {getUsDollar(parseFloat(record.amountConvenienceRate?.rate))}
          </> : <>-</>}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText><TokenQuantity quantity={getPriceI(asset?.identifier, record.amount?.amount)} asset={asset?.name}/></CustodyMainText>
          <CustodySubText>
          {record.amountConvenienceRate?.rate ? <>
          {getUsDollar(generateRecordValue(record))}
          </> : <>-</>}
            
          </CustodySubText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          {record.networkFee?.amount ? <>
          <CustodyMainText><TokenQuantity asset={feeAsset?.name} quantity={getPriceI(feeAsset?.identifier, record.networkFee?.amount)}/></CustodyMainText>
          <CustodySubText>{getUsDollar(generateFeeValue(record))}</CustodySubText>
          </> : <CustodyMainText>-</CustodyMainText>}
        </TextGroup>
      </td>
      <td>
        {record.blockchainURL ? 
        <CustodyMainText onClick={() => goTo(record.blockchainURL)}>
        <CryptoAddressBox title={record.blockchainURL}>
          <CryptoLink>{record.blockchainURL}</CryptoLink>
        </CryptoAddressBox>
        </CustodyMainText>
        :  
        <CustodyMainText onClick={() => copyAddress(record.blockchainHash)}>
        <CryptoAddressBox title={record.blockchainHash}>
          <CryptoAddress>{record.blockchainHash}</CryptoAddress>
        </CryptoAddressBox>
        </CustodyMainText>}
      </td>
      <td>{record.abraTxUID}</td>
    </tr>
  )
}

const ActionButton =  styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6F2ACD;
  border-radius: 0.5vw;
  text-transform: uppercase;
  font-size: 70%;
  padding: 0.5vw 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }

`
const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 6vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  font-size: 80%;
  overflow: hidden;
`
const CryptoLink = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  font-size: 80%;
  overflow: hidden;
  text-decoration: underline;
  color: #A399F6;
  
`
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 30px;
  letter-spacing: -0.5px;
`
export default StrategyDetailsTransactionDetails
