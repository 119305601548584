import React, { useEffect, useRef, useState } from 'react';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import useStrategy from './useStrategy';
import styled from 'styled-components';
import useInvest from './useInvest';
import useAssets from 'shared/useAssets';
import Modal from 'components/organisms/Modal';
import { CustodyStyledTable } from '../CustodyStyles';
import { SpecificDefiBalance, useConfirmDefiTransactionRequestMutation, useCreateDefiTransactionRequestMutation } from 'state/store/investApi';
import bigDecimal from 'js-big-decimal';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import { ToastType, toast } from 'components/organisms/Toast';
import { Percentage } from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import moment from 'moment';
import Tooltip from 'components/atoms/Tooltip';
import { Info } from 'assets/icons';

const DivestActionPage = () => {
  const { strategyIdentifier } = useParams();
  const { defiBalances } = useInvest();
  const [minError, setMinError] = useState(false)
  const { divestInfo, pendingTransactions } = useStrategy(strategyIdentifier ?? '');
  const { getAssetByIdentifier, getPriceFormattedI, assetsLoading } = useAssets();
  
  const getCurrentInvestment = () => {
    const data = defiBalances.data ?? [];
    const balancesFromThisStrategy = data.filter((d) => d.identifier === strategyIdentifier);
    const balancesWithAmount = balancesFromThisStrategy.filter((d) => +(d.balance?.amount ?? '0'));
    const balances = balancesWithAmount.filter((d) => +(d.yield?.apr ?? '0'));
    const currentInvestment = balances?.length ? balances[0] : undefined;
    return currentInvestment;
  };

  const currentInvestment = getCurrentInvestment();

  const { strategyDetails, strategyBalance } = useStrategy(strategyIdentifier ?? '');

  const getCurrentInvestedValues = () => {
    const data = strategyBalance.data ?? [];
    const pendingData = data.filter((d) => !d.pending);
    const totalInvestedValues = pendingData.reduce(sumDefi, new bigDecimal(0));
    return totalInvestedValues;
  };

  const sumDefi = (total: bigDecimal, balance: SpecificDefiBalance) => {
    return total.add(new bigDecimal(balance.balance?.amount ?? '0'));
  };

  const { getDollarPrice } = useAssets();
  const currentInvestedValues = getCurrentInvestedValues();
  const asset = getAssetByIdentifier(divestInfo.data?.availableAmountInAsset?.asset);

  const pendingDivestTransactions = pendingTransactions.data?.filter((t) => t.actionName === 'Divest');
  const pendingDivestAmount = pendingDivestTransactions?.reduce((sum, t) => sum.add(new bigDecimal(t.amount.amount)), new bigDecimal(0));
  const pendingDivestDollarAmount = getDollarPrice(asset?.identifier, pendingDivestAmount?.getValue())

  const [investmentAmount, setInvestmentAmount] = useState('0');

  const [showModal, setShowModal] = useState(false);

  const dollarPrice = getDollarPrice(asset?.identifier, investmentAmount.toString());
  const dollarPriceFee = divestInfo.data?.totalEstimatedFeeInConvenience?.amount;
  const [createDefiTransaction, createMeta] = useCreateDefiTransactionRequestMutation();
  const [confirmDefiTransaction, confirmMeta] = useConfirmDefiTransactionRequestMutation();

  let canvas: HTMLCanvasElement;
  const getTextWidth = (text: string, font: any) => {
    canvas = canvas ? canvas : document.createElement('canvas');
    var context = canvas.getContext('2d');
    context!.font = font;
    var metrics = context!.measureText(text);
    return Math.max(metrics.width, 10);
  };

  const submit = async () => {
    try {
      const transaction = await createDefiTransaction({
        amount: +investmentAmount,
        currency: asset?.identifier ?? '',
        transactionType: 'WITHDRAWAL',
        strategyIdentifier: strategyIdentifier ?? '',
      }).unwrap();
      await confirmDefiTransaction(transaction.publicUID).unwrap();
      toast.show({
        type: ToastType.Success,
        title: 'Your request was successful',
        content: '',
      });
      navigate('/custody');
    } catch (e) {
      toast.show({
        type: ToastType.Fail,
        title: 'There was an error with your request',
        content: 'If the problem persists, please contact our support team!',
      });
    }
  };

  const navigate = useNavigate();

  const setMax = () => {
    const amount = divestInfo.data?.availableAmountInAsset?.amount ?? '0'
    if(+amount > 0) {
      setInvestmentAmount(divestInfo.data?.availableAmountInAsset?.amount ?? '0');
    }
  }
  const renderMaxButton = +(divestInfo.data?.availableAmountInAsset?.amount ?? '0') > 0
  useCustodyRedirect();

  const [spanWidth, setSpanWidth] = useState(0)
  const valueWidth = getTextWidth(investmentAmount.toString(), '13.3px "IBM Plex Sans"');
  const spanDollarPrice = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (!spanDollarPrice.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if(spanDollarPrice.current?.offsetWidth !== spanWidth) {
        setSpanWidth(spanDollarPrice.current?.offsetWidth ?? 0); 
      }
    });
    resizeObserver.observe(spanDollarPrice.current);
    
    return () => {
      resizeObserver.disconnect();
    }
  }, [spanDollarPrice.current])

  useEffect(() => {
    const data = divestInfo.data?.minimumTransactionAmounts.find(amounts => amounts.asset === asset?.identifier)
    const minAmount = data?.amount ?? '0'
    setMinError(+investmentAmount < +minAmount)
  }, [investmentAmount])

  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          {divestInfo.isLoading || defiBalances.isLoading || strategyDetails.isLoading || assetsLoading ? (
            <>
              <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
            </>
          ) : (
            <>
              <Header>
                <PageTitle>
                  Divest from {strategyDetails.data?.name}{' '}
                  {currentInvestment ? <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</> : <></>}
                </PageTitle>
              </Header>
              <Content>
                <FormInformation>
                  <ForMoreInformation>
                    For more information about this investment strategy, please
                    <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}> click here</CustodyClickableText>
                  </ForMoreInformation>
                  {divestInfo.data?.nextInvestmentCutoff && (
                    <>
                      <NextEffectiveDateBox>
                        <p>You can cancel your divestment request till <StrongText>{moment.utc(divestInfo.data?.nextInvestmentCutoff).format('MMM Do')}</StrongText></p>
                        <p>You will continue earning yield till <StrongText>{moment.utc(divestInfo.data?.lastEffectiveDate).format('MMM Do')}</StrongText></p>
                        <p>
                          You will receive assets between
                          <span>
                            <StrongText> {moment.utc(divestInfo.data?.firstExpectedReturnDate).format('MMM Do')}</StrongText> date and
                            <StrongText> {moment.utc(divestInfo.data?.lastExpectedReturnDate).format('MMM Do')}</StrongText> date
                          </span>
                        </p>
                      </NextEffectiveDateBox>
                    </>
                  )}
                  {!!divestInfo.data?.estimatedMaxTransactionFeeBasisPoints && (
                    <EstimatedFeeBpsBox>
                      Estimated transaction fee {divestInfo.data?.estimatedMinTransactionFeeBasisPoints} bps -{' '}
                      {divestInfo.data?.estimatedMaxTransactionFeeBasisPoints} bps
                      <Tooltip text={`This is estimated based on what users paid on average in the last ${divestInfo.data.estimatedTransactionFeeIntervals} months.`}>
                        <span>
                          {' '}
                          <Info color='#fffc' size={12} />
                        </span>
                      </Tooltip>
                    </EstimatedFeeBpsBox>
                  )}
                  <p>
                    Current invested amount is <StrongText>{getPriceFormattedI(asset?.identifier, divestInfo.data?.totalAmountInAsset.amount)} </StrongText> 
                    ({getUsDollar(currentInvestedValues.getValue())})
                  </p>
                  {!!divestInfo.data?.minimumTransactionAmounts?.length &&
                  <p>
                    Minimum divest amount is {divestInfo.data?.minimumTransactionAmounts.map((min) => <StrongText key={min.asset}>{getPriceFormattedI(min.asset, min.amount)} </StrongText>)}
                  </p>
                  }
                  {pendingDivestAmount && +pendingDivestAmount.getValue() != 0 && (<>
                    <p>Please note, you have pending withdrawals in net amount of 
                      <StrongText> {getPriceFormattedI(asset?.identifier, pendingDivestAmount.getValue())}</StrongText>
                      <span> {pendingDivestDollarAmount != '-' && <>({getDollarPrice(asset?.identifier, pendingDivestAmount.getValue())})</>}</span> already in process</p> 
                  </>)}
                  {divestInfo.data?.availableAmountInAsset.amount !== divestInfo.data?.totalAmountInAsset.amount ? (
                    <>
                      <p>
                        Available amount for divest of <strong>{getPriceFormattedI(asset?.identifier, divestInfo.data?.availableAmountInAsset?.amount)}</strong>
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                  <FormInputBox>
                    <Tooltip text="The actual amount you receive may be lower than the requested amount because of estimated transaction fee, network fee, and management fee.">
                      <p>Choose amount to divest <Info color='#fffc' size={12} /></p> 
                    </Tooltip>
                    <InputArea size={valueWidth}>
                      <InputAmount size={spanWidth + valueWidth} type='number' value={investmentAmount} onChange={(e) => setInvestmentAmount(e.target.value)}></InputAmount>
                      <span ref={spanDollarPrice}>{asset?.name} { dollarPrice !== '-' && <>({dollarPrice})</> }</span>
                    </InputArea>
                    {renderMaxButton && 
                      <MaxButton onClick={() => setMax()} >MAX</MaxButton>
                    }
                  </FormInputBox>
                </FormInformation>
                <RightAlignedBox>
                  <ReviewButton
                    onClick={() => setShowModal(!showModal)}
                    disabled={minError || +(divestInfo.data?.availableAmountInAsset?.amount ?? '0') < +investmentAmount}
                  >
                    Review
                  </ReviewButton>
                </RightAlignedBox>
                {minError && <p style={{color: 'red'}}>This amount is lower than the transaction minimum allowed</p>}
              </Content>
            </>
          )}
        </CustodyNavigationWidget>
        <Modal visible={showModal} onClose={() => setShowModal(false)} header='Confirmation Page'>
          <Modal.Body>
            <ModalContent>
              <p>
                Divest{' '}
                <strong>
                  {investmentAmount} {asset?.name}
                </strong>{' '}
                {dollarPrice !== '-' && <>({dollarPrice})</>} in
                <CustodyClickableText onClick={() => navigate(`/custody/invest/details/${strategyIdentifier}`)}>
                  {' '}
                  {strategyDetails.data?.name}
                </CustodyClickableText>{' '}
                {currentInvestment && <>with Current APR of {new Percentage(currentInvestment.yield.apr).print()}</>}
              </p>
              <p>This strategy will take a few hours to execute</p>
              <p>
                Estimated network fee is{' '}
                <strong>
                  ${dollarPriceFee} {
                  !!divestInfo.data?.totalEstimatedFeeAmounts.length && 
                  <>
                    ({divestInfo.data?.totalEstimatedFeeAmounts?.map((fee) => getPriceFormattedI(fee?.asset, fee?.amount)).join(', ')}{' '})
                  </>
                  }
                </strong>
              </p>
            </ModalContent>
            {!!divestInfo.data?.stepDetails?.length && (
              <AutomationStepsBox>
                <h4>Automation Steps</h4>
                <CustodyStyledTable>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Expected Fees</th>
                      <th>Fees in USD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {divestInfo.data?.stepDetails?.map((step) => (
                      <tr>
                        <td>{step.description}</td>
                        <td>{step.estimatedFeeAmount ? getPriceFormattedI(step.estimatedFeeAmount?.asset, step.estimatedFeeAmount?.amount) : '-'}</td>
                        <td>
                          {step.estimatedConvenienceFeeAmount
                            ? getPriceFormattedI(step.estimatedConvenienceFeeAmount?.asset, step.estimatedConvenienceFeeAmount?.amount)
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CustodyStyledTable>
              </AutomationStepsBox>
            )}
          </Modal.Body>
          <Modal.Footer>
            <RightAlignedBox>
              <ReviewButton onClick={submit} disabled={createMeta.isLoading || confirmMeta.isLoading}>
                Submit
              </ReviewButton>
            </RightAlignedBox>
          </Modal.Footer>
        </Modal>
      </MarginContainer>
    </Layout>
  );
};

const MaxButton = styled.button`
  background-color: #6f2acd;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 6px 18px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`;

const ForMoreInformation = styled.p`
  margin-bottom: 8px;
`;

const FormInputBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`;
const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
`;
const NextEffectiveDateBox = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
interface InputAreaProps {
  size: number;
}
const StrongText = styled.strong`
  font-weight: 500;
`
const InputArea = styled.div<InputAreaProps>`
  position: relative;
  span {
    top: 0px;
    user-select: none;
    font-size: 13.3333px;
    left: ${(props) => props.size + 13}px;
    position: absolute;
  }
`;
const AutomationStepsBox = styled.div``;
const ModalContent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  font-size: 0.9rem;
`;
const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #a58bf2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
interface ReviewButtonProps {
  disabled: boolean;
}

const EstimatedFeeBpsBox = styled.div`
  margin-bottom: 8px;
`;

const ReviewButton = styled.button<ReviewButtonProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  background-color: ${({ disabled }) => (disabled ? '#555' : '#6F2ACDAA')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.7rem;
  opacity: 0.9;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  font-size: 1.1rem;
  padding: 6px 18px;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#555' : 'rgba(163, 153, 246, 0.8)')};
  }
`;

const USDEquivalent = styled.span`
  font-size: 0.8rem;
  grid-column: 2/3;
`;
const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

interface InputAmountProps {
  size: number;
}

const InputAmount = styled.input<InputAmountProps>`
  background-color: transparent;
  color: white;
  min-width: 32ch;
  border: 0px;
  width: ${(props) => props.size+20}px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  padding: 0px 8px 0px 8px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
`;

const PageTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default DivestActionPage;
