import { Info, LeftArrow } from 'assets/icons';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import DigitVerification from 'components/atoms/DigitVerification';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import bigDecimal from 'js-big-decimal';
import Tooltip from 'components/atoms/Tooltip';
import { Heading } from 'components/atoms/Typography';
import Modal from 'components/organisms/Modal';
import { toast, ToastType } from 'components/organisms/Toast';
import Layout from 'components/templates/Layout';
import { TypesNamesEnum } from 'enums/Button.enum';
import { PagesUrl } from 'lib/constants/config.constant';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAssets from 'shared/useAssets';
import usePermissions from 'shared/usePermissions';
import PrimeTerms from 'assets/abra-prime.docx';
import {
  AccessTokenWrapper,
  TransferAddress,
  useGetTermsServiceCheckedQuery,
  useGetWhitelistAddressesQuery,
  useSetCheckTermsServiceMutation,
  WithdrawRequest,
} from 'state/store/withdrawApi';
import styled from 'styled-components';
import useCustodyWithdraw from './extraCustodyHooks/useCustodyWithdraw';
import ConfirmValidAddress from 'pages/Withdraw/ConfirmValidAddress';
import {
  CustodyWithdrawRequestBody,
  useCreateCustodyWithdrawRequestMutation,
  useSendCustodyUsernameAndPasswordMutation,
  useValidateCustodyEmailCodeMutation,
  useValidateCustodyOTPCodeMutation,
  useSendEmailVerificationMutation,
} from 'state/store/custodyApi';
import { selectCurrentUser } from 'state/slice/auth.slice';
import CustodyNetworkCard from './CustodyNetworkCard';
import CustodyNavigationWidget from './CustodyNavigationWidget';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import { CustodyAssetComponentContainer } from './CustodyAssetComponent';
import { MarginContainer } from './Custody';
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect';
import { AssetDto } from 'state/store/api';
import CustodyAssetBalanceComponent from './CustodyAssetBalanceComponent'

interface WithdrawFromAssetProps {
  asset: string;
}

const WithdrawFromAsset: FC<WithdrawFromAssetProps> = ({ asset }) => {
  const { setSelectedAsset, selectedAsset, selectedNetwork, selectedFeeAsset, custodyAssetNetworkInformation, loadingNetworks, maxUnverifiedWithdraw, minWithdraw } =
    useCustodyWithdraw();

  useEffect(() => {
    setSelectedAsset(asset);
  }, [asset, setSelectedAsset]);

  const getAddresses = useGetWhitelistAddressesQuery(null, { refetchOnMountOrArgChange: true });
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [startWithdrawModal, setStartWithdrawModal] = useState(false);
  const [withdrawRequest, setWithdrawRequest] = useState<WithdrawRequest>();
  const [addresses, setAddresses] = useState<TransferAddress[]>([]);
  const [address, setAddress] = useState<TransferAddress>();
  const [password, setPassword] = useState('');
  const [otpCode, setOtpCode] = useState<string[]>([]);
  const [emailCode, setEmailCode] = useState<string[]>([]);
  const assets = useAssets();
  const navigate = useNavigate();
  //const [fee, setFee] = useState<'deducted' | 'added'>('deducted');

  const [createWithdrawRequest, createWithdrawRequestInfo] = useCreateCustodyWithdrawRequestMutation();
  const [passwordValidation, passwordValidationInfo] = useSendCustodyUsernameAndPasswordMutation();
  const [sendEmailValidationCode, sendEmailValidationCodeInfo] = useSendEmailVerificationMutation();
  const [emailValidation, emailValidationInfo] = useValidateCustodyEmailCodeMutation();
  const [otpValidation, otpValidationInfo] = useValidateCustodyOTPCodeMutation();

  const [loading, setLoading] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState<'terms' | 'password' | 'otp' | 'email' | 'confirm'>('terms');
  const [amount, setAmount] = useState<string>('0');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { username } = usePermissions();
  const [validationToken, setValidationToken] = useState<AccessTokenWrapper>();
  const [feeSameAsAsset, setFeeSameAsAsset] = useState(false);
  const getTerms = useGetTermsServiceCheckedQuery();
  const [checkTerms] = useSetCheckTermsServiceMutation();
  const [warningAmount, setWarningAmount] = useState({
    amountNotEnough: false,
    amountExactlyTheSame: false,
    estimatedFeeSurpassAvailable: false,
  });


  useEffect(() => {
    const feeAsset = custodyAssetNetworkInformation?.availableAmountInFeeAsset?.asset ?? '';
    const availableFee = custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? 0;
    const estimatedFee = custodyAssetNetworkInformation?.estimatedFee?.amount ?? 0;
    const availableAmountInAsset = custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? 0;
    const asset = custodyAssetNetworkInformation?.availableAmountInAsset?.asset ?? '';
    const amountSelected = +(amount ?? 0);
    let amountNotEnough = false;
    let estimatedFeeSurpassAvailable = +estimatedFee > +availableFee;
    let amountExactlyTheSame = false
    if (feeAsset === asset) {
      amountNotEnough = +availableAmountInAsset < +amountSelected + +estimatedFee;
      amountExactlyTheSame = +amount > 0 &&  (amount === getMaximumWithdrawalAmount())
    }
    setWarningAmount({ amountNotEnough, estimatedFeeSurpassAvailable, amountExactlyTheSame });
  }, [amount, selectedFeeAsset, custodyAssetNetworkInformation]);

  useEffect(() => {
    const sameAsset = custodyAssetNetworkInformation?.availableAmountInAsset.asset === custodyAssetNetworkInformation?.availableAmountInFeeAsset.asset;
    setFeeSameAsAsset(sameAsset);
  }, [custodyAssetNetworkInformation]);

  const setFirstStep = () => {
    if (termsAccepted) setWithdrawStep('password');
    else setWithdrawStep('terms');
  };

  const startWithdrawRequest = async () => {
    const withdraw = {
      amount: generateAmountDeducted(),
      whitelistAddressId: address?.id || 0,
      currency: asset,
      network: selectedNetwork?.network ?? '',
    };
    const withdrawRequest = await createWithdrawRequest(withdraw).unwrap();
    setWithdrawRequest(withdrawRequest);
  };

  useEffect(() => {
    if (withdrawStep === 'password' && startWithdrawModal) {
      startWithdrawRequest();
    }
  }, [withdrawStep, startWithdrawModal]);

  useEffect(() => {
    if (getTerms.currentData) {
      var data = getTerms.currentData;
      var checked = data.checked;
      setTermsAccepted(checked);
      if (checked && !startWithdrawModal) {
        setWithdrawStep('password');
      }
    }
  }, [getTerms]);

  const clickTermsAccepted = async () => {
    setTermsAccepted(!termsAccepted);
    await checkTerms(!termsAccepted);
    getTerms.refetch();
  };

  const navigateToAddresses = () => {
    navigate(`${PagesUrl.ADDRESSES}`);
  };

  const generateMaximumAmount = () => {
    if (address) {
      if (!address.verified) {
        const maxUnverifiedAmountNumber = maxUnverifiedWithdraw ? +maxUnverifiedWithdraw.amount : 0;
        const maxInAsset = custodyAssetNetworkInformation?.availableAmountInAsset ? +custodyAssetNetworkInformation?.availableAmountInAsset?.amount : 0;
        return Math.min(maxUnverifiedAmountNumber, maxInAsset);
      }
    }
    return +(custodyAssetNetworkInformation?.availableAmountInAsset?.amount ?? 0);
  };

  useEffect(() => {
    const minimalAmount = +(minWithdraw?.amount ?? 0);
    const maximumAmount = generateMaximumAmount();
    if (Number.isNaN(amount)) {
      setAmount(minimalAmount.toString());
    } else if (+amount < minimalAmount) {
      setAmount(minimalAmount.toString());
    } else if (+amount > maximumAmount) {
      setAmount(maximumAmount.toString());
    }
  }, [selectedNetwork]);

  useEffect(() => {
    setLoading(
      createWithdrawRequestInfo.isLoading ||
        passwordValidationInfo.isLoading ||
        sendEmailValidationCodeInfo.isLoading ||
        emailValidationInfo.isLoading ||
        otpValidationInfo.isLoading
    );
  }, [createWithdrawRequestInfo, passwordValidationInfo, sendEmailValidationCodeInfo, emailValidationInfo, otpValidationInfo]);

  const markAsVerified = (address: TransferAddress) => {
    setAddress({ ...address, verified: true });
    const updatedAddresses = addresses.map((a) => (a.id === address.id ? { ...a, verified: true } : a));
    setAddresses(updatedAddresses);
  };

  useEffect(() => {
    const listener = captureEnterEvent as unknown as EventListener;
    document.addEventListener('keydown', listener);
    return function cleanup() {
      document.removeEventListener('keydown', listener);
    };
  }, [withdrawStep, termsAccepted, password, otpCode, emailCode]);

  const captureEnterEvent = (e: KeyboardEvent<HTMLElement>) => {
    const enterIsPressed = e.key === 'Enter';
    const valid = currentStepIsValid();
    if (enterIsPressed && valid) {
      proceedWithdraw();
    }
  };

  const stepsModalHeader = () => {
    if (withdrawStep === 'terms') return 'Terms of Service';
    if (withdrawStep === 'password') return 'Step 1 of 3 - Password Validation';
    if (withdrawStep === 'otp') return 'Step 2 of 3 - Authenticator Code Validation';
    if (withdrawStep === 'email') return 'Step 3 of 3 - Email Code';
    if (withdrawStep === 'confirm') return 'Confirmation';
    return 'Withdraw Request';
  };

  useEffect(() => {
    if (amount === '-') {
      setMinError(false);
      return;
    }
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMinError(true);
      return;
    }
    if (!selectedNetwork) {
      return;
    }
    
    if (+amount < +(minWithdraw?.amount ?? 0)) {
      setMinError(true);
    } else {
      setMinError(false);
    }
  }, [amount, selectedNetwork]);

  useEffect(() => {
    if (amount === '-') return;
    const invalidNumber = Number.isNaN(amount);
    if (invalidNumber) {
      setMaxError(true);
      return;
    }
    if (!selectedNetwork) {
      setMaxError(true);
      return;
    }
    if (!address) {
      setMaxError(true);
    }
    const amountDeducted = generateAmountDeducted();
    const maximumAmount = +generateMaximumAmount();
    if (amountDeducted > maximumAmount) {
      setMaxError(true);
    } else {
      setMaxError(false);
    }
  }, [amount, selectedNetwork /* fee */]);

  useEffect(() => {
    if (amount === '-') return;
    if (!Number.isNaN(amount)) {
      const parsedAmount = +amount;
      const steps = selectedAsset?.fractionDigits?.toString().split('.');
      if (steps && steps[1]) {
        const amountSteps = amount.toString().split('.');
        if (amountSteps && amountSteps[1]) {
          const size = steps[1].length;
          const amountSize = amountSteps[1].length;
          if (amountSize > size) {
            setAmount(parsedAmount.toFixed(size));
          }
        }
      }
    }
  }, [amount, selectedAsset]);

  useEffect(() => {
    if (getAddresses.currentData) {
      setAddresses(getAddresses.currentData.whitelist ?? []);
    } else setAddresses([]);
  }, [getAddresses]);

  const registeredAddressesForThatNetwork = addresses
    .filter((address) => selectedNetwork?.network === address.network)
    .filter((address) => address.asset === selectedAsset?.name);

  const toggleSelectedAddress = (transferAddress: TransferAddress) => {
    address ? setAddress(undefined) : setAddress(transferAddress);
  };

  const startWithdraw = () => {
    setStartWithdrawModal(true);
  };

  const currentStepIsValid = () => {
    if (withdrawStep === 'terms') {
      return termsAccepted;
    }
    if (withdrawStep === 'password') {
      return password;
    }
    if (withdrawStep === 'otp') {
      return otpCode.length === 6;
    }
    if (withdrawStep === 'email') {
      return emailCode.length === 8;
    }
    return true;
  };

  const proceedWithdraw = async () => {
    if (withdrawStep === 'terms') {
      const withdraw: CustodyWithdrawRequestBody = {
        amount: generateAmountDeducted(),
        whitelistAddressId: address?.id || 0,
        currency: selectedAsset?.identifier ?? '',
        network: selectedNetwork?.name ?? '',
      };
      const withdrawRequest = await createWithdrawRequest(withdraw).unwrap();
      setWithdrawRequest(withdrawRequest);
      setWithdrawStep('password');
    }
    if (withdrawStep === 'password') {
      const tokenPromise = passwordValidation({ password, username }).unwrap();
      const emailCodePromise = sendEmailValidationCode(withdrawRequest?.id?.toString() || '').unwrap();
      const [token, _] = await Promise.all([tokenPromise, emailCodePromise]);
      setValidationToken(token);
      setWithdrawStep('otp');
    }
    if (withdrawStep === 'otp') {
      const validation = { mfaToken: validationToken?.accessToken ?? '', otpCode: otpCode.join('') };
      await otpValidation({ withdrawRequestId: withdrawRequest?.id ?? 0, ...validation }).unwrap();
      setWithdrawStep('email');
    }
    if (withdrawStep === 'email') {
      setWithdrawStep('confirm');
    }
    if (withdrawStep === 'confirm') {
      try {
        await emailValidation({ code: emailCode.join(''), withdrawRequestId: withdrawRequest?.id ?? 0 }).unwrap();
        toast.show({
          type: ToastType.Success,
          title: 'Your withdraw request was submitted',
          content: 'Please wait while the network processes your request, you will receive a confirmation email once the process has been completed',
          duration: 15000,
        });
        navigate(PagesUrl.CUSTODY);
      } catch (e) {
        setWithdrawStep('email');
      }
    }
  };

  const cancelWithdraw = () => {
    setStartWithdrawModal(false);
    setFirstStep();
    setPassword('');
    setOtpCode([]);
    setEmailCode([]);
  };

  const keyDownOtp = (event: { keyCode: number }, indx: number) => {
    if (event.keyCode === 8) {
      if (otpCode[indx]) {
        const updatedCureentValue = otpCode.slice(0, indx);
        setOtpCode(updatedCureentValue);
        return;
      }
      const updatedValue = otpCode.slice(0, indx - 1);
      setOtpCode(updatedValue);
    }
  };

  const changeOtp = (event: any) => {
    setOtpCode([...otpCode, event?.target?.value]);
  };

  const keyDownEmail = (event: { keyCode: number }, indx: number) => {
    if (event.keyCode === 8) {
      if (emailCode[indx]) {
        const updatedCureentValue = emailCode.slice(0, indx);
        setEmailCode(updatedCureentValue);
        return;
      }
      const updatedValue = emailCode.slice(0, indx - 1);
      setOtpCode(updatedValue);
    }
  };

  const changeEmail = (event: any) => {
    setEmailCode([...emailCode, event?.target?.value]);
  };

  const pasteEmailCode = async () => {
    const text = await navigator.clipboard.readText();
    if (text.trim().length === 8) {
      setEmailCode(text.trim().split(''));
    }
  };

  const n = (text: string | undefined) => {
    if (Number.isNaN(text)) return 0;
    else return parseFloat(text ?? '0');
  };

  const generateAmountSent = () => {
    // if (fee != 'added' && feeSameAsAsset) {
    //   return Math.max(n(amount) - n(custodyAssetNetworkInformation?.estimatedFee.amount), 0);
    // } else {
    return n(amount);
    // }
  };

  const generateAmountDeducted = () => {
    return n(amount);
  };

  const displayBalancesToDeduct = () => {
    if (feeSameAsAsset) {
      let amountInKind = custodyAssetNetworkInformation?.availableAmountInAsset;
      return (
        <>
          <strong>{assets.getPriceFormattedI(amountInKind?.asset, amountInKind?.amount)}</strong>
        </>
      );
    } else {
      let amountInKind = custodyAssetNetworkInformation?.availableAmountInAsset;
      let amountInFee = custodyAssetNetworkInformation?.availableAmountInFeeAsset;
      return (
        <>
          <strong>
            {assets.getPriceFormattedI(amountInKind?.asset, amountInKind?.amount)} and {assets.getPriceFormattedI(amountInFee?.asset, amountInFee?.amount)}
          </strong>
        </>
      );
    }
  };

  const checkPendingAddressHasError = (address?: TransferAddress) => {
    if (address) {
      const isPending = !address.usedWithdrawCompleted && address.usedWithdrawRequest;
      const hasError = address.usedWithdrawError;
      return isPending && hasError;
    }
    return false;
  };

  const calculateAmountStep = (selectedAsset: AssetDto): string => {
    return new bigDecimal(Math.pow(10, -(selectedAsset?.fractionDigits ?? 0))).getValue();
  };

  const user = useSelector(selectCurrentUser);
  const smaAccountNumber = user?.organization?.smaAccountNumber;

  const verifiedAddresses = registeredAddressesForThatNetwork.filter((a) => a.verified);
  const pendingConfirmFundAddresses = registeredAddressesForThatNetwork.filter((a) => !a.verified && a.usedWithdrawCompleted);
  const pendingAddresses = registeredAddressesForThatNetwork.filter((a) => !a.usedWithdrawCompleted && a.usedWithdrawRequest && !a.usedWithdrawError);
  const newAddresses = registeredAddressesForThatNetwork.filter((a) => !a.usedWithdrawRequest || (a.usedWithdrawRequest && a.usedWithdrawError));

  const availableAmountValue = custodyAssetNetworkInformation?.availableAmountInAsset?.amount;
  const totalAmountValue = custodyAssetNetworkInformation?.totalAmountInAsset?.amount;
  const availableAmountIsDifferent = new bigDecimal(availableAmountValue).compareTo(new bigDecimal(totalAmountValue)) != 0;

  const getMaximumWithdrawalAmount = () => {
    const availableAmount =  generateMaximumAmount();
    if(!feeSameAsAsset)
      return availableAmount.toString()
    const estimatedFee = custodyAssetNetworkInformation?.estimatedFee?.amount ?? 0;
    const value = new bigDecimal(availableAmount).subtract(new bigDecimal(estimatedFee)).getValue()
    return value
  }

  return (
    <WithdrawBox>
      <Heading variant='h3'>Withdraw {smaAccountNumber && `from SMA Account ${smaAccountNumber}`}</Heading>

      <Container>
        {custodyAssetNetworkInformation && (
          <>
            <Paragraph>
              Current account balance is <strong>{assets.getPriceFormattedI(asset, custodyAssetNetworkInformation?.totalAmountInAsset?.amount)}</strong>
            </Paragraph>
            {availableAmountIsDifferent && (
              <>
                <Paragraph>
                  Available amount for withdrawal is
                  <Tooltip text='Your balance available for withdrawal may be lower than the actual account balance because of estimated network fee as well as management fee and gas fee reserve requirements'>
                    <strong>
                      {' '}
                      {assets.getPriceFormattedI(asset, custodyAssetNetworkInformation?.availableAmountInAsset?.amount)} <Info color='#fffc' size={12} />
                    </strong>
                  </Tooltip>
                </Paragraph>
              </>
            )}
            {!feeSameAsAsset && (
              <Paragraph>
                This withdraw has an estimated fee of{' '}
                {assets.getPriceFormattedI(custodyAssetNetworkInformation?.estimatedFee.asset, custodyAssetNetworkInformation?.estimatedFee?.amount)} and your
                available {assets.getAssetByIdentifier(custodyAssetNetworkInformation?.estimatedFee?.asset)?.name} balance is{' '}
                {assets.getPriceFormattedI(
                  custodyAssetNetworkInformation?.estimatedFee.asset,
                  custodyAssetNetworkInformation?.availableAmountInFeeAsset?.amount
                )}
              </Paragraph>
            )}
          </>
        )}
        {loadingNetworks && <LoadingBar />}
      </Container>

      {!loadingNetworks && registeredAddressesForThatNetwork.length === 0 && (
        <Container>
          <Paragraph>
            You do not have a registered address to transfer {selectedAsset?.name}, please
            <ClickHere onClick={navigateToAddresses}> click here </ClickHere>
            to register.
          </Paragraph>
        </Container>
      )}
      {!loadingNetworks && registeredAddressesForThatNetwork.length > 0 && (
        <>
          {!address && (
            <Container>
              <Paragraph>
                Please select the address you would like to withdraw your <strong>{selectedAsset?.name}</strong> assets to.
              </Paragraph>
              <Paragraph>
                If you prefer to transfer to a different address, please <ClickHere onClick={navigateToAddresses}> click here </ClickHere>
                to register.
              </Paragraph>
            </Container>
          )}
          {address && (
            <Container>
              {address.usedWithdrawCompleted && address.verified && (
                <Paragraph>This address has been verified and used before to successfully transfer assets.</Paragraph>
              )}
              {address.usedWithdrawCompleted && !address.verified && (
                <Paragraph>This address has been used before to transfer, for your security, confirm if the funds were received.</Paragraph>
              )}
              {!address.usedWithdrawCompleted && address.usedWithdrawRequest && (
                <Paragraph>This transaction failed on the network. Please verify the address and either retry the transaction or delete the address.</Paragraph>
              )}
              {!address.usedWithdrawCompleted && !address.usedWithdrawRequest && (
                <Paragraph>
                  This address has never been used before, you are first required to perform a small test transaction to validate the address.
                </Paragraph>
              )}
              <Paragraph>
                If you prefer to transfer to a different address, please<ClickHere onClick={navigateToAddresses}> click here </ClickHere>
                to register.
              </Paragraph>
            </Container>
          )}
          <WithdrawAddressAndAmountBox>
            <AddressesBox collapsed={!!address}>
              {verifiedAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Verified Address{verifiedAddresses.length > 1 ? 'es' : ''}</h5>}
                    {verifiedAddresses.map((a) => (
                      <EmbeddedAddressBox key={a.id} address={a} selectedAddress={address} toggleSelectedAddress={toggleSelectedAddress} />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingConfirmFundAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Please Verify</h5>}
                    {pendingConfirmFundAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        markAsVerified={markAsVerified}
                        notSelectable
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {pendingAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Verification in Progress</h5>}
                    {pendingAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        notSelectable
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {newAddresses.length > 0 && (
                <>
                  <GroupboxAddress show={!address}>
                    {!address && <h5>Unverified Address{newAddresses.length > 1 ? 'es' : ''}</h5>}
                    {newAddresses.map((a) => (
                      <EmbeddedAddressBox
                        key={a.id}
                        address={a}
                        selectedAddress={address}
                        toggleSelectedAddress={toggleSelectedAddress}
                        hasError={checkPendingAddressHasError(a)}
                      />
                    ))}
                  </GroupboxAddress>
                </>
              )}
              {selectedNetwork && address && custodyAssetNetworkInformation && (
                <>
                  <CustodyNetworkCard max={generateMaximumAmount()} network={selectedNetwork} info={custodyAssetNetworkInformation} min={minWithdraw?.amount}></CustodyNetworkCard>
                </>
              )}
            </AddressesBox>
            {selectedAsset && selectedNetwork && (
              <InputActionsBox>
                <LabelInputBox>
                  {address && !address?.verified && (
                    <InfoText>
                      For security measures, you may only withdraw up to
                      <Tooltip text='For your security, withdrawals to unverified addresses are capped at $100. Once funds have been successfully transferred, this restriction will be lifted.'>
                        <strong>
                          {' '}
                          {assets.getPriceFormatted(selectedAsset?.name, generateMaximumAmount())} <Info color='#fffc' size={15} />{' '}
                        </strong>
                      </Tooltip>
                    </InfoText>
                  )}
                  <ConfirmValidAddress address={address} markAsVerified={markAsVerified} />
                  <LabelAmount htmlFor='inputAmount'>Select the Amount of {selectedAsset?.name} to withdraw</LabelAmount>
                  <InputBox>
                    <InputAmount
                      id='inputAmount'
                      placeholder='Amount'
                      type='number'
                      step={calculateAmountStep(selectedAsset)}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <MaxButton onClick={() => setAmount(getMaximumWithdrawalAmount())}>Max</MaxButton>
                  </InputBox>
                </LabelInputBox>
                <Button
                  height='44'
                  disabled={
                    !amount ||
                    minError ||
                    warningAmount.amountNotEnough ||
                    warningAmount.estimatedFeeSurpassAvailable ||
                    maxError ||
                    !address ||
                    (address && !address?.verified && address.usedWithdrawRequest)
                  }
                  error={minError || maxError}
                  buttonType={TypesNamesEnum.ACCENT}
                  label='Next'
                  onClick={startWithdraw}
                />

                {warningAmount && !warningAmount.amountNotEnough && warningAmount.estimatedFeeSurpassAvailable && (
                  <p>
                    <ErrorMessage>
                      This withdrawal request will fail since the SMA account balance is lower than the estimated network fee. Please add funds before retrying
                      the transaction
                    </ErrorMessage>
                  </p>
                )}
                {warningAmount && warningAmount.amountExactlyTheSame && (
                  <p>
                    <WarningMessage>
                      This transaction could fail if actual network fee ends up being higher than the estimate due to an unexpected change in the network activity
                    </WarningMessage>
                  </p>
                )}
                {warningAmount && warningAmount.amountNotEnough && (
                  <p>
                    <ErrorMessage>
                      This withdrawal request will fail since the SMA account balance is lower than the estimated network fee. Please add funds before retrying
                      the transaction
                    </ErrorMessage>
                  </p>
                )}

                {address && !address?.verified && address.usedWithdrawRequest && (
                  <p>
                    <ErrorMessage>Unable to withdraw to this address until you have confirmed the receipt of test funds</ErrorMessage>
                  </p>
                )}
                {minError && (
                  <p>
                    <ErrorMessage>This amount is lower than the transaction minimum allowed.</ErrorMessage>
                  </p>
                )}
                {maxError && (
                  <p>
                    <ErrorMessage>Exceeds maximum amount of {generateMaximumAmount()}</ErrorMessage>
                  </p>
                )}
              </InputActionsBox>
            )}
          </WithdrawAddressAndAmountBox>
        </>
      )}
      <Modal visible={startWithdrawModal} header={stepsModalHeader()}>
        <Modal.Body>
          {withdrawStep === 'terms' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please confirm the withdrawal address, otherwise you may lose your funds.</ParagraphModal>
                <ParagraphModal>
                  In addition, you are required to click on the following link to review the
                  <ExternalLink href={PrimeTerms} download='Abra Prime User Agreement' target='_blank'>
                    {' '}
                    Abra Prime User Agreement{' '}
                  </ExternalLink>{' '}
                  and “Accept Terms” to continue.
                </ParagraphModal>

                <Checkbox text='Accept Terms' checked={termsAccepted} onChange={() => clickTermsAccepted()} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'password' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>To proceed with your withdraw request, please confirm your password.</ParagraphModal>
                <br />
                <Input
                  label='Your password here'
                  inputProps={{
                    value: password,
                    type: 'password',
                    onChange: (e: any) => {
                      setPassword(e.target.value);
                    },
                  }}
                />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'otp' && (
            <>
              <NormalSpacingBox>
                <ParagraphModal>Please type the code provided from your authenticator app. This is the same application you used to login.</ParagraphModal>
                <DigitVerification code={otpCode} onChange={changeOtp} onKeyDown={keyDownOtp} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'email' && (
            <>
              <NormalSpacingBox onPaste={pasteEmailCode}>
                <ParagraphModal>Please check your email, you should have received an eight-digit verification code from Abra.</ParagraphModal>
                <DigitVerification code={emailCode} onChange={changeEmail} onKeyDown={keyDownEmail} digitsLength={8} />
              </NormalSpacingBox>
            </>
          )}
          {withdrawStep === 'confirm' && (
            <>
              <ParagraphModalHeader>
                The amount of{' '}
                <strong>
                  {generateAmountDeducted()} {selectedAsset?.name} + Actual Network Fee
                </strong>{' '}
                will be deducted from your balance of <strong>{displayBalancesToDeduct()}.</strong>
              </ParagraphModalHeader>
              <ParagraphSent>Transaction Preview</ParagraphSent>
              <SpacingBox>
                <LongParagraph>
                  <strong>Network:</strong> {selectedNetwork?.name}
                </LongParagraph>
                <LongParagraph>
                  <strong>Address:</strong> {address?.address}
                </LongParagraph>
                {address?.memo && (
                  <LongParagraph>
                    <strong>Destination Tag / MEMO:</strong> {address.memo}
                  </LongParagraph>
                )}
                <LongParagraph>
                  <strong>Amount To Be Sent: </strong> {assets.getPriceFormatted(selectedAsset?.name, generateAmountSent())}
                </LongParagraph>
                <LongParagraph>
                  <strong>Estimated Network Fee:</strong>{' '}
                  {assets.getPriceFormattedI(custodyAssetNetworkInformation?.estimatedFee.asset, custodyAssetNetworkInformation?.estimatedFee?.amount)}
                </LongParagraph>
              </SpacingBox>
              <br></br>
              <ParagraphModal>Once you confirm, we will submit the transaction to the network.</ParagraphModal>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button height='44' buttonType={TypesNamesEnum.SECONDAY} label='Cancel' onClick={cancelWithdraw} />
          <Button
            height='44'
            buttonType={TypesNamesEnum.ACCENT}
            label={withdrawStep === 'confirm' ? 'Confirm' : 'Next'}
            onClick={proceedWithdraw}
            disabled={!currentStepIsValid()}
            isLoading={loading}
          />
        </Modal.Footer>
      </Modal>
    </WithdrawBox>
  );
};

const CustodyWithdraw = () => {
  const [selectedAsset, setSelectedAsset] = useState('');
  const { asset } = useParams();
  useCustodyRedirect();

  useEffect(() => {
    if (asset) {
      setSelectedAsset(asset);
    }
  }, [asset]);

  const { restart, balances } = useCustodyWithdraw();

  useEffect(() => {
    restart();
  }, []);

  const assetsHook = useAssets();
  const withdrawInfo = balances.data?.map(balance => {
    const asset = assetsHook.getAssetByIdentifier(balance.availableBalance.asset)
    return {
      assetBalance: balance.availableBalance,
      dollarBalance: balance.availableValue,
      asset
    }
  })
  withdrawInfo?.sort((a, b) => +b.dollarBalance.amount  - +a.dollarBalance.amount );
  
  const canGoBack = selectedAsset;

  
  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        {selectedAsset && (
          <CustodyNavigationWidget action={canGoBack ? () => {
            setSelectedAsset('');
            restart();
          } : undefined} textAction='Change Asset'>
            {<WithdrawFromAsset asset={selectedAsset} />}
          </CustodyNavigationWidget>
        )}
        {!selectedAsset && (
          <CustodyNavigationWidget>
            <h1>Withdraw Crypto</h1>
            <WithdrawContainer>
              <CustodyAssetComponentContainer>
                <h3>Select the asset</h3>
                {withdrawInfo?.map((info) => (
                  <CustodyAssetBalanceComponent dollar={info.dollarBalance.amount} balance={info.assetBalance.amount} key={info.asset?.identifier} isClickable={true} asset={info.asset} onClick={() => setSelectedAsset(info.asset?.identifier ?? '')} />
                ))}
              </CustodyAssetComponentContainer>
            </WithdrawContainer>
          </CustodyNavigationWidget>
        )}
      </MarginContainer>
    </Layout>
  );
};

interface GroupboxAddressProps {
  show: boolean;
}
const GroupboxAddress = styled.div<GroupboxAddressProps>`
  border: ${({ show }) => (show ? '1px solid rgba(255, 255, 255, 0.1)' : '0px solid black')};
  padding: ${({ show }) => (show ? '4px 8px' : '0')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
`;
interface TextInputProps {
  invalid?: boolean;
}

const AddressBoxInfo = styled.div`
  padding: 1vh 1vw 1vh 1vh;
`;

const AddressesBoxContainer = styled.div`
  display: flex;
`;
const InputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
`
const MaxButton = styled.button`
  background-color: #6f2acd;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 6px 18px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`;

interface BackButtonProps {
  show: boolean;
}
const BackButton = styled.div<BackButtonProps>`
  transition: all 0.3s ease-in;
  width: ${(props) => (props.show ? '14px' : '0px')};
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
const ErrorMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: #970003;
`;
const WarningMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: #E9992899;
`;
const InfoText = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  font-weight: 500;
`;
const NormalSpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;
const SpacingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
`;
const ParagraphModalHeader = styled.p`
  font-size: 0.8rem;
  margin-top: 3vh;
  opacity: 0.9;
`;
const ParagraphModal = styled.p`
  font-size: 0.8rem;
  opacity: 0.9;
`;
const LongParagraph = styled.p`
  font-size: 0.7rem;
  opacity: 0.8;
  word-break: break-all;
`;
const LabelInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 2vh;
`;

const LabelAmount = styled.label`
  font-size: 0.7rem;
  opacity: 0.9;
`;
const InputActionsBox = styled.div`
  background-color: rgba(163, 153, 246, 0.1);
  padding: 1vw 1vw;
  border-radius: 0px 0px 12px 12px;
  flex-grow: 1;
  justify-content: space-between;
  gap: 1vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
`;

const WithdrawAddressAndAmountBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
interface AddressesBoxProps {
  collapsed: boolean;
}
const AddressesBox = styled.div<AddressesBoxProps>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  background-color: rgba(163, 153, 246, 0.1);
  padding: 1vw 1vw;
  margin-top: 2vh;
  gap: ${({ collapsed }) => (collapsed ? '0' : '1vh')};
  border-radius: ${({ collapsed }) => (collapsed ? '12px 12px 0px 0px' : '12px')};
  transition: all 0.4s ease-in;
`;

interface AddressBoxProps {
  visible: boolean;
  selected: boolean;
  address: TransferAddress;
  notSelectable?: boolean;
}

const AddressBox = styled.div<AddressBoxProps>`
  background-color: ${({ address }) => (address?.verified ? '#17B96BBB' : 'rgba(255, 255, 255, 0.2)')};
  position: relative;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  padding: 0;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2vw;
  border-radius: 12px;
  overflow: hidden;
  word-break: break-all;
  font-size: 0.7rem;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
  cursor: ${({ notSelectable }) => (notSelectable ? 'not-allowed' : 'pointer')};
  opacity: 1;
  ${({ selected, visible, address }) => {
    if (selected) {
      return 'background-color:' + address?.verified ? '#17B96BDD' : 'rgba(255, 255, 255, 0.4);';
    }
    if (!visible) {
      return 'height: 0; overflow: hidden; padding: 0; opacity: 0;';
    }
  }}
  ${({ notSelectable, address }) =>
    notSelectable
      ? ''
      : `
  &:hover {
    background-color: ${address?.verified ? '#17B96BFF' : 'rgba(255, 255, 255, 0.4)'};
  }
    `};
`;

const ParagraphSent = styled.p`
  opacity: 0.9;
  font-size: 0.8rem;
  margin-top: 3vh;
  margin-bottom: 0;
`;
const Paragraph = styled.p`
  opacity: 0.9;
  font-size: 0.8rem;
`;

const InputAmount = styled.input<TextInputProps>`
  background-color: transparent;
  color: white;
  border: 0;
  border-bottom: ${({ invalid }) => (!invalid ? '1px solid rgba(255,255,255,0.8)' : '2px solid rgba(255,20,20,0.8)')};
  width: 28ch;
  font-size: 0.7rem;
  max-height: 22px;
  max-width: 100%;
  display: flex;
`;

const ClickHere = styled.strong`
  cursor: pointer;
  color: #eb92ff;
`;

const WithdrawBox = styled.div``;

export default CustodyWithdraw;

interface AddressBoxForWithdrawProps {
  address: TransferAddress;
  toggleSelectedAddress: (a: TransferAddress) => void;
  markAsVerified?: (a: TransferAddress) => void;
  selectedAddress?: TransferAddress;
  notSelectable?: boolean;
  hasError?: boolean;
}
const EmbeddedAddressBox: FC<AddressBoxForWithdrawProps> = (props) => {
  const { address, toggleSelectedAddress, selectedAddress, markAsVerified, notSelectable, hasError } = props;
  return (
    <>
      <AddressBox
        notSelectable={!(!notSelectable || hasError)}
        address={address}
        visible={!selectedAddress}
        selected={address?.id === selectedAddress?.id}
        key={address.id}
        onClick={() => (!notSelectable || hasError) && toggleSelectedAddress(address)}
      >
        <AddressesBoxContainer>
          <BackButton show={address.id === selectedAddress?.id}>
            <LeftArrow />
          </BackButton>
          <AddressBoxInfo>
            <div>
              <div>
                <strong>Asset: </strong> {address.asset ?? ' --- '}
              </div>
              <div>
                <strong>Network: </strong>
                {address.networkName}
              </div>
              <div>
                <strong>Address: </strong>
                {address.address}
              </div>
              {address.memo && (
                <div>
                  <strong>Memo: </strong>
                  {address.memo}
                </div>
              )}
            </div>
            {address.notes && (
              <div>
                <strong>Notes: </strong>
                {address.notes}
              </div>
            )}
          </AddressBoxInfo>
        </AddressesBoxContainer>
        <TooltipBox>
          {markAsVerified && <ConfirmValidAddress address={address} button={true} markAsVerified={markAsVerified} />}
          {hasError && (
            <Tooltip text='There was an error during the process on the network. You can try to do another transaction or delete this address.' autoDismiss>
              <Icon name='Info' color='red' size={20} />
            </Tooltip>
          )}
          {!hasError && notSelectable && (
            <Tooltip text='You are unable to withdraw to this address until you have confirmed the receipt of test funds.' autoDismiss>
              <Icon name='Info' color='orange' size={20} />
            </Tooltip>
          )}
        </TooltipBox>
      </AddressBox>
    </>
  );
};

const TooltipBox = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const ExternalLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;

const WithdrawContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  gap: 2vh;
  margin-top: 3vh;
`;
