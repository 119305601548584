import React, { FC, useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";

import { Text } from "components/atoms/Typography";
import styled, { keyframes } from "styled-components";
import { LoadingSize } from "lib/constants/config.constant";
import * as animationData from "assets/loading/loading.json";
import { palette } from "lib/theme";
interface ILoadingProps {
  size?: SpinnerSizeEnum;
  showText?: boolean;
  showRoundSpinner?: boolean;
  text?: string;
  spinnerColor?: string;
}

export enum SpinnerSizeEnum {
  DEFAULT = "default",
  INPUT = "input",
  BUTTON = "button",
  LARGE = "large",
  SORTING = "sorting",
}


const Loading: FC<ILoadingProps> = ({
  size = SpinnerSizeEnum.DEFAULT,
  showText = true,
  showRoundSpinner = false,
  text = "Loading data",
  spinnerColor = palette.accent.main,
}) => {
  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loadingRef.current) return;

    lottie.loadAnimation({
      container: loadingRef.current,
      animationData: animationData,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  }, []);

  const loadingText = text;

  return (
    <>
      {!showRoundSpinner && (
        <ContainerAbraSpinner size={size}>
          <div
            style={{
              width: LoadingSize[size].width,
              height: LoadingSize[size].height,
            }}
            ref={loadingRef}
          />
          {showText && <Text>{loadingText}</Text>}
        </ContainerAbraSpinner>
      )}
      {showRoundSpinner && (
        <ContainerRoundSpinner spinnerColor={spinnerColor} size={size}>
          <div className="spin">
            <div className="arc arc_start"></div>
            <div className="arc arc_end"></div>
            <div className="dot"></div>
          </div>
          {showText && <Text>{loadingText}</Text>}
        </ContainerRoundSpinner>
      )}
    </>
  );
};

export default Loading;

const ContainerAbraSpinner = styled.div<ILoadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ContainerRoundSpinner = styled.div<ILoadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  .spin {
    width: ${({ size }) => LoadingSize[size].width};
    height: ${({ size }) => LoadingSize[size].height};
    animation: ${spin} 3s linear infinite;
  }
  .arc {
    position: absolute;
    border-radius: 50%;
    border: 2px solid;
    width: ${({ size }) => LoadingSize[size].width};
    height: ${({ size }) => LoadingSize[size].height};
  }

  .arc_start {
    border-color: ${palette.white.light0};
  }

  .arc_end {
    border-color: transparent
      ${(props) =>
        `${props.spinnerColor} ${props.spinnerColor} ${props.spinnerColor}`};
  }

  .dot {
    display: ${({ size }) =>
      LoadingSize[size] === LoadingSize.input || LoadingSize.button
        ? "none"
        : "block"} !important;
    width: 8.57px;
    height: 8.57px;
    background: ${palette.accent.main};
    border-radius: 50%;
    transform: translate(-80%, -0%) translate(10px, 0px);
  }
`;
