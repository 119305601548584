import Layout from 'components/templates/Layout';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustodyMainText, CustodyStyledTable } from './CustodyStyles';
import CustodyNavigationWidget from './CustodyNavigationWidget';
import styled from 'styled-components';
import { MarginContainer } from './Custody';
import { PagesUrl } from 'lib/constants/config.constant';
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect';
import { useGetAvailableDocumentsQuery, DocumentsAvailability } from 'state/store/custodyApi';

const STATEMENT_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.STATEMENT;
const INVOICE_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.INVOICE;
const TAX_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.TAX;

const CustodyDocuments: React.FC = () => {
  const availableDocumentsQuery = useGetAvailableDocumentsQuery();
  const availableDocuments = availableDocumentsQuery.data ?? [];
  const navigate = useNavigate();
  useCustodyRedirect();

  const getDate = (yearMonth: string): Date => {
    const [yearString, monthString] = yearMonth.split('-');
    return new Date(parseInt(yearString), parseInt(monthString) - 1);
  };

  const formatMonthYear = (yearMonth: string) => {
    const date = getDate(yearMonth);
    const month = date.toLocaleString('default', { month: 'short' });
    const capitalMonth = month.charAt(0).toUpperCase() + month.slice(1);
    const year = date.getFullYear();
    return `${capitalMonth} ${year}`;
  };

  const calculateMonthRange = (yearMonth: string) => {
    const date = getDate(yearMonth);
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);
    return { startOfMonth, endOfMonth };
  };

  const getRedirectUrl = (baseUrl: string, yearMonth: string): string => {
    return `${baseUrl}/${yearMonth.split('-')[0]}/${yearMonth.split('-')[1]}`;
  };

  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        <CustodyNavigationWidget>
          <StatementsTitle>Custody Documents</StatementsTitle>
          {availableDocumentsQuery.isLoading && <p>Loading...</p>}
          {!availableDocumentsQuery.isLoading && availableDocuments.length === 0 && <CustodyMainText>You don't have any documents to review at the moment</CustodyMainText>}
          {availableDocuments.length > 0 && (
            <CustodyStyledTable>
              <thead>
                <tr>
                  <th>Statement</th>
                  <th>Invoice</th>
                  <th>Tax form</th>
                </tr>
              </thead>
              <tbody>{displayDocumentsTable(availableDocuments)}</tbody>
            </CustodyStyledTable>
          )}
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );

  function displayDocumentsTable(availableDocuments: DocumentsAvailability[]): React.ReactNode {
    return availableDocuments.map((docs) => {
      const yearMonth = docs.yearMonth;
      const { startOfMonth, endOfMonth } = calculateMonthRange(yearMonth);
      return (
        <tr key={docs.yearMonth}>
          <td>
            <ButtonsArea>
              <ActionButton isVisible={docs.hasStatement} onClick={() => navigate(getRedirectUrl(STATEMENT_URL, yearMonth))}>
                <span>{formatMonthYear(yearMonth)}</span>
              </ActionButton>
            </ButtonsArea>
          </td>
          <td>
            <ButtonsArea>
              <ActionButton isVisible={docs.hasInvoice} onClick={() => navigate(getRedirectUrl(INVOICE_URL, yearMonth))}>
                <span>{formatMonthYear(yearMonth)}</span>
              </ActionButton>
            </ButtonsArea>
          </td>
          <td>
            <ButtonsArea>
              <ActionButton isVisible={docs.hasTax} onClick={() => navigate(`${getRedirectUrl(TAX_URL, yearMonth)}?from=${startOfMonth}&to=${endOfMonth}`)}>
                <span>{formatMonthYear(yearMonth)}</span>
              </ActionButton>
            </ButtonsArea>
          </td>
        </tr>
      );
    });
  }
};

interface ActionButtonProps {
  isVisible?: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  width: 8vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;

const StatementsTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`
ActionButton.defaultProps = {
  isVisible: true,
};

const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`;
export default CustodyDocuments;
