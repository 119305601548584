import React, { FC, useState, useEffect, useCallback, createRef } from "react"
import {
  Styled2FAContainer,
  Styled2FAInputWrapper,
  Styled2FAInput,
} from "../../../styles/components/DigitVerification"
import { IDigitVerificationProps } from "interfaces/components/atoms/Atom.interface"
import classNames from "classnames"

import Alert from "./../../molecules/Alert"
import { AlertType } from "./../../molecules/Alert/Alert.interface"
import { isNumeric } from "../../../lib/utils/validations"

const DigitVerification: FC<IDigitVerificationProps> = ({
  code,
  onChange,
  onKeyDown,
  className = "",
  errorMessage = "",
  dismissAlert,
  DigitVerificationProps,
  digitsLength = 6
}) => {
  const digits = [...Array(digitsLength)].map(_ => ({ref: createRef()}))

  const [value, setValue] = useState([...code])
  const [error, setError] = useState(errorMessage)
  const [isAlertVisible, setIsAlertVisible] = useState(
    errorMessage?.length > 0
  )

  const setCodeValue = useCallback((current = "") => {
    setValue(current)
  }, [])

  const handleDismissInputAlert = () => {
    setIsAlertVisible(false)
    dismissAlert()
  }

  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    setCodeValue(code)
  }, [code, setCodeValue])

  useEffect(() => {
    setError(errorMessage)
    setIsAlertVisible(Boolean(errorMessage))
  }, [errorMessage])

  return (
    <Styled2FAContainer>
      {error && (
        <Alert
          message={error}
          variant={AlertType.ERROR}
          isVisible={isAlertVisible}
          onDismiss={handleDismissInputAlert}
        />
      )}
      <Styled2FAInputWrapper
        error={Boolean(errorMessage)}
      >
        {digits.map((item, indx, arr) => (
          <Styled2FAInput
            data-cy={`digitverification_input_${indx}`}
            key={`2fa-input-${indx}`}
            ref={item.ref}
            maxLength="1"
            autoFocus={indx == 0}
            value={code?.slice(indx, indx + 1) || null}
            className={classNames({
              filled: value[indx],
            })}
            onChange={(event: any) => {
              if (isNumeric(event?.target?.value)) {
                handleChange(event)
                arr[indx + 1]?.ref?.current?.focus()
              }
            }}
            onKeyDown={(event) => {
              if (onKeyDown) {
                onKeyDown(event, indx)
              }
              if (event.keyCode === 8) {
                arr[indx - 1]?.ref?.current?.focus()
              }
            }}
            $
            {...DigitVerificationProps}
          />
        ))}
      </Styled2FAInputWrapper>
    </Styled2FAContainer>
  )
}

export default DigitVerification
