import React, { FC } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';

interface Props {
  quantity: string | number;
  asset?: string;
}
const TokenQuantity: FC<Props> = ({ quantity = '', asset = '' }) => (
  <Tooltip text={`${quantity} ${asset}`}>
    <FlexContainer>
      <TotalTokenQuantity>{quantity}</TotalTokenQuantity>
      <span>{asset}</span>
    </FlexContainer>
  </Tooltip>
);

const FlexContainer = styled.div`
  display: flex;
  cursor: help;
  align-items: center;
  gap: 0.5ch;
`;
const TotalTokenQuantity = styled.span`
  display: inline-block;
  max-width: 12ch;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default TokenQuantity;
