import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import Button from "components/atoms/Button";
import { TypesNamesEnum } from "enums/Button.enum";
import { selectTokenToValidate } from "state/slice/auth.slice";
import styled from "styled-components";
import {
  IconContainer,
  Overlay,
} from "components/organisms/Modal/Modal.styles";
import Icon from "components/atoms/Icon";
import { palette } from "lib/theme";
import { copyTextToClipboard } from "shared";
import { useNavigate } from "react-router-dom";
import { PagesUrl } from "lib/constants/config.constant";

const Enable2FA = () => {
  const tokenToValidate = useSelector(selectTokenToValidate);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showZoomIn, setShowZoomIn] = useState(false);
  const navigate = useNavigate();

   useEffect(() => {
     if (!tokenToValidate?.barcodeUri) {
       navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`);
     }
   }, [tokenToValidate]);
  
  const handleOnSubmit = () => {
    navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.TURN_ON_2FA}`);
  };

  return (
    <div style={{ marginTop: "2vh" }} hidden={!tokenToValidate?.barcodeUri}>
      <QrContainer>
        <QrWrapper
          onClick={() => setShowQrCode(true)}
          onMouseOver={() => setShowZoomIn(true)}
          onMouseLeave={() => setShowZoomIn(false)}
        >
          {showZoomIn && (
            <ZoomInWrapper>
              <Icon name="ZoomIn" size={50} />
            </ZoomInWrapper>
          )}

          <QRCodeCanvas value={tokenToValidate?.barcodeUri ?? ""} size={128}/>
        </QrWrapper>
        <AdditionalTextContainer>
          <AdditionalText >
            {tokenToValidate?.secret}
          </AdditionalText>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              copyTextToClipboard(tokenToValidate?.secret ?? "");
            }}
          >
            <Icon name="CopyRefer" size={24} color={palette.purple.light} />
          </div>
        </AdditionalTextContainer>
      </QrContainer>
      {showQrCode && (
        <Overlay visible={showQrCode}>
          <div style={{ paddingTop: "4vh" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "245px",
                flexDirection: "column",
                gap: "11px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  placeContent: "end",
                  width: "313px",
                  height: "24px",
                  paddingTop: "4px",
                }}
              >
                <IconContainer
                  onClick={() => setShowQrCode(false)}
                  style={{ width: "auto", height: "auto" }}
                >
                  <Icon
                    name="CrossModal"
                    size="24"
                    opacity={1}
                    color={palette.white.main}
                  />
                </IconContainer>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "8px",
                  borderRadius: "8px",
                  width: "248px",
                  height: "248px",
                }}
              >
                <QRCodeCanvas
                  value={tokenToValidate?.barcodeUri ?? ""}
                  size={231}
                />
              </div>
            </div>
          </div>
        </Overlay>
      )}
      <div style={{ marginTop: "3vh" }}>
        <Button
          buttonType={TypesNamesEnum.ACCENT}
          height="48"
          width='100%'
          onClick={handleOnSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

const QrContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
`;

const QrWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  background-color:red;
`;

const AdditionalText = styled.p`
  overflow-wrap: break-word;
  overflow: hidden;
`;

const ZoomInWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
`;

const AdditionalTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 8px;
  max-width: 220px;
`;

export default Enable2FA;
