import React, { FC, useState } from 'react';
import Icon from 'components/atoms/Icon';
import { Text } from 'components/atoms/Typography';
import styled from 'styled-components';
import { palette, zIndex } from 'lib/theme';
import Image from 'components/atoms/Image';
import { useDispatch } from 'react-redux';
import { logout } from 'state/slice/auth.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultSettings, PagesUrl } from 'lib/constants/config.constant';
import { getText } from 'shared/locale-helper';
import { api } from 'state/store/api';
import usePermissions from 'shared/usePermissions';
import 'cookie-store';

interface IProfile {
  userName: string;
  userEmail: string;
  userAvatar: string;
  scrollingUp: boolean;
}
const ProfileMenu: FC<IProfile> = ({ userName, userEmail, userAvatar, scrollingUp }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isListVisible, setIsListVisible] = useState(false);
  const permissions = usePermissions();
  const logOut = () => {
    dispatch(logout());
    eraseCookies();
    dispatch(api.util.resetApiState());
    navigate(PagesUrl.AUTHENTICATION);
  };

  const eraseCookies = () => {
    CookieStore.getAll().then((cookies: any) =>
      cookies.forEach((cookie: any) => {
        CookieStore.delete(cookie.name);
      })
    );
  };

  const goToAddressesPage = () => {
    navigate(PagesUrl.ADDRESSES);
  };

  return (
    <Box>
      <Container>
        <IconContainer onClick={() => setIsListVisible((_isVisible) => !_isVisible)}>
          <Icon name={isListVisible ? 'UpArrowMid' : 'DownArrowMid'} size={16} />
        </IconContainer>
      </Container>
      {isListVisible && (
        <Wrapper>
          <Menu
            style={{
              borderRadius: '8px',
              border: `1px solid ${palette.darkBackgroundContrast.light10}`,
              backgroundColor: scrollingUp ? '#33314d' : '',
              backdropFilter: !scrollingUp ? 'blur(50px)' : '',
              zIndex: zIndex.popover,
            }}
          >
            <Row height='83px'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  WebkitAlignItems: 'center',
                  paddingTop: '24px',
                  paddingBottom: '16px',
                  gap: '8px',
                }}
              >
                {!userAvatar || userAvatar === DefaultSettings.DEFAULT_UNSET_AVATAR_URL ? (
                  <Icon name='DefaultAvatar' size={40} />
                ) : (
                  <Image circle src={userAvatar} />
                )}
                <Box>
                  <Text>{userName}</Text>
                  <Text size='small' color={palette.darkBackgroundContrast.light70}>
                    {userEmail}
                  </Text>
                </Box>
              </div>
            </Row>
            <Row hasBorderTop hasClickFunction onClick={() => navigate(PagesUrl.PROFILE)}>
              Profile
            </Row>
            {permissions.canWithdraw && (
              <Row hasBorderTop hasClickFunction onClick={goToAddressesPage}>
                Transfer Addresses
              </Row>
            )}
            <Row hasBorderTop hasClickFunction onClick={() => logOut()}>
              Log out
            </Row>
          </Menu>
        </Wrapper>
      )}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${palette.night.light10};
  border: 1px solid;
  border-image: linear-gradient(44.4deg, ${palette.darkBackgroundContrast.light40} 0%, ${palette.darkBackgroundContrast.light0} 97.23%);
  position: absolute;
  z-index: 7;
  min-width: 280px;
  top: 8px;
  right: 0px;

  &::before {
    backdrop-filter: blur(50px);
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;
const Row = styled.div<{
  hasBorderTop?: boolean;
  hasClickFunction?: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  height: ${({ height }) => height || '47px'};
  align-items: center;
  ${({ hasClickFunction }) => hasClickFunction && `cursor: pointer`};
  ${({ hasBorderTop }) => hasBorderTop && `border-top: 1px solid ${palette.darkBackgroundContrast.light10}`};
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Wrapper = styled.div`
  position: relative;
`;

const IconContainer = styled.div``;

export default ProfileMenu;
