import { Copy } from 'assets/icons';
import Image from 'components/atoms/Image';
import { toast, ToastType } from 'components/organisms/Toast';
import React from 'react';
import { AssetDto } from 'state/store/api';
import { CustodyBalance, CustodyNetworkMap } from 'state/store/custodyApi';
import styled from 'styled-components';
import { CustodyMainText, CustodyStyledPrintableTable, CustodyStyledTable } from '../CustodyStyles';
import useCustodyStatement from './useCustodyStatement';
import { useParams } from 'react-router-dom';
import { RealizedGainLossResponse } from 'state/store/statementsApi';
import moment from 'moment';
import useAssets from 'shared/useAssets';

const CustodyStatementGainLossDetails = () => {
  const { month, year } = useParams();
  const { realizedGainLoss } = useCustodyStatement({ month, year });
  const gainLoss = realizedGainLoss?.data ?? [];
  return (
    <div>
      <TokenSummaryTitle>Gain / Loss Details</TokenSummaryTitle>
      <CustodyStyledPrintableTable>
        <thead>
          <tr>
            <th>Date</th>
            <th>Strategy</th>
            <th>Action</th>
            <th>Transaction Type</th>
            <th>Closing Amount</th>
            <th>Closing Quantity</th>
            <th>Closing Price</th>
            <th>Cost Basis</th>
            <th>Transaction Id</th>
            <th>Duration</th>
            <th>Realized Gain / Loss**</th>
          </tr>
        </thead>
        <tbody>
          {gainLoss.map((record, index) => (
            <CustodySummaryRow key={index} record={record} />
          ))}
        </tbody>
      </CustodyStyledPrintableTable>
    </div>
  );
};

interface CustodySummaryRowProps {
  record: RealizedGainLossResponse;
}
const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({ record }) => {
  const { getPriceFormattedI, getAsset } = useAssets();
  const strategyName = record.strategyName ?? '-';
  const actionName = record.actionName ?? '-';
  const transactionCategory = record.transactionCategory ?? '-';
  return (
    <tr>
      <td>
        <MainRowText>
          {/* Date */}
          {record.closingDate}
        </MainRowText>
      </td>
      <td>
        <MainRowText>{strategyName}</MainRowText>
      </td>
      <td>
        <MainRowText>{actionName}</MainRowText>
      </td>
      <td>
      <MainRowText>{transactionCategory}</MainRowText>
      </td>
      <td>
        <MainRowText>
          {/* Amount */}
          {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
          {getPriceFormattedI(record.amount.asset, record.amount.amount)}
          {/* Closing Amount */}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
          {/* Closing Price */}
          {getPriceFormattedI(record.closingPrice.quoteCurrency, record.closingPrice.rate)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
            {/* Overall cost basis */}
            {getPriceFormattedI(record.totalCostBasis?.asset, record.totalCostBasis?.amount)}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
          {/* Transaction Id */}
          {record.abraTxUID}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
          {/* Duration */}
          {record.duration}
        </MainRowText>
      </td>
      <td>
        <MainRowText>
          {/* Realized Gain/Loss */}
          {getPriceFormattedI(record.realizedAmount.asset, record.realizedAmount.amount)}
        </MainRowText>
      </td>
    </tr>
  );
};

interface GainLossProps {
  loss: boolean;
}
const GainLossArea = styled.span<GainLossProps>`
  ${(p) => (p.loss ? 'color: #EC396B;' : '')}
`;

const MockText = styled.span`
  color: #e99928;
`;

const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`;
const ImageBox = styled.div`
  max-width: calc(min(4vw, 60px));
  min-width: calc(min(4vw, 60px));
`;
const CopyBox = styled.div`
  width: 20px;
  height: 20px;
`;
const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 10vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  max-width: 10vw;
  overflow: hidden;
`;
const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const MainRowText = styled.span``;

const SubRowText = styled.span`
  font-weight: 400;
  color: #ffffff;
  font-size: 80%;
  opacity: 0.7;
  cursor: inherit;
`;

const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
`;
const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 6vh;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;
export default CustodyStatementGainLossDetails;
