import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CheckMark: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
  active
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.64018 3.73178C1.21591 3.37821 0.585343 3.43554 0.231779 3.85982C-0.121785 4.28409 -0.0644614 4.91466 0.359816 5.26822L1.64018 3.73178ZM9.76822 1.64018C10.1218 1.21591 10.0645 0.585343 9.64018 0.231779C9.21591 -0.121785 8.58534 -0.0644614 8.23178 0.359816L9.76822 1.64018ZM3.61589 6.67991L2.97571 7.44813L3.61589 6.67991ZM4.32009 6.61589L3.55187 5.97571L4.32009 6.61589ZM0.359816 5.26822L2.97571 7.44813L4.25607 5.91169L1.64018 3.73178L0.359816 5.26822ZM5.08831 7.25607L9.76822 1.64018L8.23178 0.359816L3.55187 5.97571L5.08831 7.25607ZM2.97571 7.44813C3.61212 7.97848 4.55797 7.89249 5.08831 7.25607L3.55187 5.97571C3.72865 5.76357 4.04393 5.7349 4.25607 5.91169L2.97571 7.44813Z"
      fill="white"
      fillOpacity={active ? "1" : "0.2"}
    />
  </svg>
);

export default CheckMark;

