import { BOOST_WITHDRAW, useIsFeatureEnabledQuery, CUSTODY_WITHDRAW, CUSTODY_DEPOSIT, CUSTODY_INVEST } from './../state/store/withdrawApi';
import { selectCurrentUser } from 'state/slice/auth.slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentToken } from 'state/slice/auth.slice';
import jwt_decode from 'jwt-decode';
import { accountManagerSelector, setPermission } from 'state/slice/permissions.slice';

interface Token {
  permissions: string[];
}

const usePermissions = () => {
  const token = useSelector(selectCurrentToken);
  const isAccountManager = useSelector(accountManagerSelector);
  const [isOwner, setIsOwner] = useState(false);
  const [isWithdrawEnabled, setIsWithdrawEnabled] = useState(false);
  const [isCustodyWithdrawEnabled, setIsCustodyWithdrawEnabled] = useState(false);
  const [isCustodyDepositEnabled, setIsCustodyDepositEnabled] = useState(false);
  const [isCustodyInvestEnabled, setIsCustodyInvestEnabled] = useState(false);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const withdrawEnabled = useIsFeatureEnabledQuery(BOOST_WITHDRAW);
  const custodyWithdrawEnabled = useIsFeatureEnabledQuery(CUSTODY_WITHDRAW);
  const custodyDepositEnabled = useIsFeatureEnabledQuery(CUSTODY_DEPOSIT);
  const custodyInvestEnabled = useIsFeatureEnabledQuery(CUSTODY_INVEST);

  const canWithdraw = isOwner && isWithdrawEnabled;
  const canWithdrawCustody = isOwner && isCustodyWithdrawEnabled
  const canInvest = isOwner && isCustodyInvestEnabled
  const canDivest = isOwner && isCustodyInvestEnabled
  const canDepositCustody = isCustodyDepositEnabled

  useEffect(() => {
    const organization = user?.organizations.find((c) => user?.organization?.id == c.organizationId);
    const isOwner = !!(organization && organization.owner);
    setIsOwner(isOwner);
  }, [user]);

  useEffect(() => {
    if (withdrawEnabled.data) {
      setIsWithdrawEnabled(withdrawEnabled.data.enabled);
    }
  }, [withdrawEnabled]);

  useEffect(() => {
    if (custodyInvestEnabled.data) {
      setIsCustodyInvestEnabled(custodyInvestEnabled.data.enabled);
    }
  }, [custodyInvestEnabled]);

  useEffect(() => {
    if (custodyWithdrawEnabled.data) {
      setIsCustodyWithdrawEnabled(custodyWithdrawEnabled.data.enabled);
    }
  }, [custodyWithdrawEnabled]);

  useEffect(() => {
    if (custodyDepositEnabled.data) {
      setIsCustodyDepositEnabled(custodyDepositEnabled.data.enabled);
    }
  }, [custodyDepositEnabled]);

  useEffect(() => {
    if (token) {
      const decoded = jwt_decode(token) as Token;
      if (decoded.permissions) {
        const accountManager = decoded.permissions.includes('account_manager');
        dispatch(setPermission(accountManager));
      }
    }
  }, [token]);

  return {
    isAccountManager,
    isOwner,
    canWithdraw,
    canInvest,
    canWithdrawCustody,
    canDepositCustody,
    username: user?.email ?? '',
    products: user?.organization?.products,
    canDivest,
    isCustodyInvestEnabled
  };
};

export default usePermissions;
