import React, { useEffect, VFC } from "react"
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom"
import { Heading, Text } from "components/atoms/Typography"
import {
  StyledHeading,
  StyledFormWrapper,
  StyledForm,
  StyledAuthenticationContainer,
  StyleAuthenticationFormContainer,
  StyleAuthenticationCarouselContainer,
} from "styles/components/authentication"
import { IAuthenticationProps } from "interfaces/components/templates/Templates.inteface"
import Footer from "components/molecules/Footer/Footer"
import SignInEmail from "components/templates/SignIn"
import ResetPassword from "components/templates/ResetPassword"
import TwoFactorVerification from "components/templates/TwoFactorVerification"

import { getText } from "shared/locale-helper"
import { PagesUrl } from "lib/constants/config.constant"
import { palette } from "lib/theme"
import { RootState, useTypedSelector } from "state/store"
import SetupPassword from "components/templates/SetupPassword/SetupPassword"
import Enable2FA from "components/templates/SetupPassword/Enable2FA"
import TurnOn2FA from "components/templates/SetupPassword/TurnOn2FA"
import styled from 'styled-components'
import { toast, ToastType } from 'components/organisms/Toast'

const Authetication: VFC = () => {
  const { tokenToValidate } = useTypedSelector(
    (state: RootState) => state.auth
  )

  const [searchParams] = useSearchParams();
  const hasOnboardingParam = searchParams.has('onboarding')
  useEffect(() => {
    if(hasOnboardingParam) {
      const done = searchParams.get('onboarding') === 'done'
      const failed = searchParams.get('onboarding') === 'failed'
      if(done){
        toast.show({content: "You're all set! Please log in to your account", title: 'Success', type: ToastType.Success, duration: 30000})
      }
      if(failed){
        toast.show({content: 'Something went wrong during the sign-up process. Please try again, or reach out to our support team', title: 'Failure', type: ToastType.Fail, duration: 30000})
      }
    }
  }, [])

  useEffect(() => {
    localStorage.clear()
  }, [])

  const location = useLocation()
  const navigate = useNavigate()

  const getSettings = (): Partial<IAuthenticationProps> => {
    switch (location.pathname) {
      case `${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`:
        return {
          heading: "Sign in"
        }
      case `${PagesUrl.AUTHENTICATION}${PagesUrl.TWO_FACTOR_AUTH}`:
        return {
          heading: "Verify your identity",
          description:
            "Enter the code from your authenticator app below to continue.",
          goBack: () => {
            navigate(-1)
          },
        }
      case `${PagesUrl.AUTHENTICATION}${PagesUrl.SETUP_PASSWORD}`:
        return {
          heading: "Set up a password",
          goBack: () => {
            navigate(-1)
          },
        }
      case `${PagesUrl.AUTHENTICATION}${PagesUrl.ENABLE_2FA}`:
        return {
          heading: "Enable Abra Two Factor Authentication",
          description: (
            <Text
              size="small"
              letterSpacing="-0.40"
              color={palette.secondary.secondaryFont}
            >
              1. Download any authenticator app (we like Google Authenticator).
              <br />
              2. Scan the QR code or manually copy this key into the
              authenticator app.
            </Text>
          ),
          goBack: () => {
            navigate(-1)
          },
        }
      case `${PagesUrl.AUTHENTICATION}${PagesUrl.TURN_ON_2FA}`:
        return {
          heading: "Turn on 2FA",
          description:
            "Enter the code from your authenticator app below to continue.",
          goBack: () => {
            navigate(-1)
          },
        }
      default:
        return {
          heading: ""
        }
    }
  }

  return (
    <React.Fragment>
      <StyledAuthenticationContainer>
        <CarrouselContainer>
          <StyleAuthenticationCarouselContainer />
        </CarrouselContainer>
        <StyleAuthenticationFormContainer>
          <FormContainer>
            <StyledFormWrapper>
              <StyledHeading>
                <Heading variant="h2">
                  {getSettings().heading}
                </Heading>
                <Text size="medium" color={palette.secondary.secondaryFont}>
                  {getSettings().description}
                </Text>
              </StyledHeading>
              <StyledForm >
                <Routes>
                  <Route
                    index
                    element={
                      <Navigate
                        to={`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`}
                      />
                    }
                  />
                  <Route path={PagesUrl.SIGN_IN} element={<SignInEmail />} />
                  <Route
                    path={PagesUrl.TWO_FACTOR_AUTH}
                    element={
                      tokenToValidate ? (
                        <TwoFactorVerification />
                      ) : (
                        <Navigate
                          to={`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`}
                        />
                      )
                    }
                  />
                  <Route
                    path={PagesUrl.SETUP_PASSWORD}
                    element={<SetupPassword />}
                  />
                  <Route
                    path={PagesUrl.ENABLE_2FA}
                    element={
                      tokenToValidate ? (
                        <Enable2FA />
                      ) : (
                        <Navigate
                          to={`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`}
                        />
                      )
                    }
                  />
                  <Route
                    path={PagesUrl.TURN_ON_2FA}
                    element={
                      tokenToValidate ? (
                        <TurnOn2FA />
                      ) : (
                        <Navigate
                          to={`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`}
                        />
                      )
                    }
                  />
                  <Route
                    path={PagesUrl.RESET_PASSWORD}
                    element={<ResetPassword />}
                  />
                </Routes>
              </StyledForm>
            </StyledFormWrapper>
            <Footer />
          </FormContainer>
        </StyleAuthenticationFormContainer>
      </StyledAuthenticationContainer>
    </React.Fragment>
  )
}

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
      display: none;
  }
`

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`


export default Authetication
