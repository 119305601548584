import React from 'react';
import useAssets from 'shared/useAssets';
import styled from 'styled-components';
import getUsDollar from "../../../shared/dollarFormat";
import { CustodyMainText, CustodySubText } from '../../pages/custody/CustodyStyles';
import Tooltip from '../Tooltip';

interface Props {
    balance: {
        asset: string;
        amount: string;
    },  
    value?: {
        asset: string;
        amount: string;
    },
}

const InkindAssetRenderer = (props: Props) => {

    const assetsLookup = useAssets();

    if (!props.balance?.asset || props.balance?.asset === 'undefined') {
        return <>--</>
    }
    
    const humanReadableBalanceAsset = assetsLookup.getAssetByIdentifier(props.balance.asset);

    const renderedInDollars = getUsDollar(props.value?.amount ?? '0');

    return <>
      <Tooltip text={`${Number(props.balance?.amount)} ${humanReadableBalanceAsset?.name}`}>
        <FlexContainer>
          <CustodyMainText>
            <TotalTokenQuantity>{Number(props.balance?.amount)}</TotalTokenQuantity>
          </CustodyMainText>
          <span>{humanReadableBalanceAsset?.name}&nbsp;</span>
        </FlexContainer>
      </Tooltip>
        {props.value ? <CustodySubText>({renderedInDollars})</CustodySubText> : null}
    </>;
      
}

const FlexContainer = styled.div`
  display: flex;
  cursor: help;
  align-items: center;
  gap: 0.5ch;
`;

const TotalTokenQuantity = styled.span`
  display: inline-block;
  max-width: 10ch;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
`;


export default InkindAssetRenderer