import React from 'react';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useParams } from 'react-router-dom';
import Layout from 'components/templates/Layout';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import PrintIcon from 'assets/icons/PrintIcon';
import styled from 'styled-components';
import moment from 'moment';
import useCustodyInvoice from './useCustodyInvoice';
import LoadingStage from 'pages/LoadingStage';
import useAssets from 'shared/useAssets';
import { Info } from 'assets/icons';
import getUsDollar from 'shared/dollarFormat';
import { CustodyStyledTable } from '../CustodyStyles';

const CustodyInvoice: React.FC = () => {
  const { month, year } = useParams();
  const { smaAccountNumber, data } = useCustodyInvoice({ year, month });
  const invoiceData = data?.data;
  const successResponse = data?.isSuccess;
  const isLoading = data?.isLoading;
  const isError = data?.isError;
  const assets = useAssets();
  const feeDetails = invoiceData?.feeDetails || [];
  const renderFeeDetails = () => {
    return (
      <>
        <CustodyStyledTable>
          <thead>
            <tr>
              <th></th>
              <th>Average Daily Balance</th>
              <th>Abra Management Fee</th>
            </tr>
          </thead>
          <tbody>
            {feeDetails.map((fee, index) => (
                <tr key={index}>
                  <td>{fee.feeDescription} Fee</td>
                  <td>{assets.getPriceFormattedI(fee.averageBalance.asset, fee.averageBalance.amount)}</td>
                  <td>{fee.feeBasisPoints} bps yearly</td>
                </tr>
              )
            )}
          </tbody>
        </CustodyStyledTable>
      </>
    );
  };

  const renderInvoice = () => {
    return (
      <>
        <InvoiceBox>
          <BoxValues>
            <PairTitle>SMA Account Number:</PairTitle>
            <PairValue>{smaAccountNumber}</PairValue>
          </BoxValues>
          <BoxValues>
            <PairTitle>Invoice Date:</PairTitle>
            <PairValue>{moment.utc(invoiceData?.issuedDate).format('MMM DD YYYY')}</PairValue>
          </BoxValues>
          <BoxValues>
            <PairTitle>Invoice Period:</PairTitle>
            <PairValue>
              {moment.utc(invoiceData?.range?.startInclusive).format('MMM DD YYYY')} to{' '}
              {moment.utc(invoiceData?.range?.endExclusive).subtract(1, 'day').format('MMM DD YYYY')}
            </PairValue>
          </BoxValues>
          <Separator />
          <BoxValues>
            <PairTitle>Monthly Management Fee:</PairTitle>
            <PairValue>{getUsDollar(invoiceData?.managementFee.amount)}</PairValue>
          </BoxValues>
          <Separator />
          {renderFeeDetails()}
        </InvoiceBox>
      </>
    );
  };

  const renderError = () => {
    const message = data?.error?.status === 404 ? 'Invoice not found' : 'Error loading invoice';
    return (
      <>
        <BoxValues>
          <PairValue>
            <Notes>
              <Info />
              <span>
                &nbsp;
                {message}
              </span>
            </Notes>
          </PairValue>
        </BoxValues>
      </>
    );
  };

  const print = () => {
    window.print();
    return false;
  };

  const renderFeeCollectionMessage = () => {
    return (
      <BoxValues>
        <PairValue>
          <Notes>
            <Info />
            <span>&nbsp; We are going to automatically sweep the equivalent amount in crypto from your account</span>
          </Notes>
        </PairValue>
      </BoxValues>
    );
  };

  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        <CustodyNavigationWidget>
          <TitlePage>
            Invoice{' '}
            <PrintButton onClick={print}>
              <PrintIcon />
            </PrintButton>
          </TitlePage>
          {isLoading && (
            <LoadingContainer>
              <LoadingStage />
            </LoadingContainer>
          )}
          {successResponse && renderInvoice()}
          {isError && renderError()}
          {invoiceData && invoiceData?.paymentStatus !== 'COMPLETED' && renderFeeCollectionMessage()}
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};

const InvoiceBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-bottom: 4vh;
`;

const BoxValues = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

const PrintButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f2acd99;
  padding: 6px;
  border-radius: 50%;
  transition: all 1s;
  &:hover {
    background-color: #6f2acd;
  }
`;

const Separator = styled.div`
  border-top: 1px solid rgba(255,255,255,0.3);
`
const TitlePage = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
  @media print {
    color: black !important;
    font-weight: bold;
  }
`;

const PairTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.9);
  @media print {
    color: black !important;
  }
`;

const PairValue = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: -0.48px;
  @media print {
    color: black !important;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Notes = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 6px 12px 6px 4px;
  border-radius: 8px;
`;

export default CustodyInvoice;
